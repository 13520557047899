import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable()
export class UploadServiceService {
	url = this.globalService.url;
	// header = this.globalService.getHeader();

	constructor(private globalService: GlobalService, private http: HttpClient) { }

	/**
	 * Upload files to server
	 */
	uploadFile(files: FileList, type = 'any') {
		const data = new FormData();
		for (let i = 0; i <= files.length; i++) {
			data.append('file', files[i]);
		}
		data.append('type', type);
		return this.http.post(`${this.url}/v1/uploads`, data, {
			headers: {
				Authorization: localStorage.getItem('token')
			}
		});
	}

	uploadOneFile(file: File, path?: string) {
		const data = new FormData();
		!path ? (path = 'images') : '';

		data.append('file', file);
		data.append('type', 'any');

		return this.http.post(`${this.url}/v1/uploads`, data, {
			headers: {
				Authorization: localStorage.getItem('token')
			}
		});
	}
}
