import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LoginService } from './services/login.service';
declare let $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
    idleTimedOut = false;
    idleLastPing?: Date = null;
    idleSecond = 1800;
    idleTimeoutSecond = 10;
    idleCountDown = 0;

	constructor(private router: Router, private idle: Idle, private keepalive: Keepalive, private loginService: LoginService) {
        /***** Idle Setup *****/
        // sets an idle timeout.
        idle.setIdle(this.idleSecond);
        // sets a timeout period. after this, the user will be considered timed out.
        idle.setTimeout(this.idleTimeoutSecond);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.resetIdle();
        });

        idle.onTimeout.subscribe(() => {
            this.idleTimedOut = true;
            this.loginService.logout();
        });

        idle.onIdleStart.subscribe(() => {
            if (this.router.url !== '/login' && localStorage.getItem('token')) {
                $('#app-idle-prompt').modal('show');
                this.loginService.logout();
            } else this.resetIdle();
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleCountDown = countdown;
        });

        this.keepalive.interval(1800);

        this.keepalive.onPing.subscribe(() => this.idleLastPing = new Date());

        this.resetIdle();
    }

	ngOnInit() {
		/**
		 * Check Expire Date
		 */
		const now = Date.now();
		const expiration = localStorage.getItem('isLoggedinExpire');
		const token = localStorage.getItem('token');
		if (token && expiration) {
			if (Number(expiration) < now) this.loginService.logout();
		}
	}

    resetIdle() {
        this.idle.watch();
        this.idleTimedOut = false;
    }
}
