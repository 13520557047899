import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	isProduction = environment.production;
	constructor(public cUser: CurrentUserService) {}

	ngOnInit() {
		this.keepOpen();
	}

	get isDistributor() {
		return this.cUser.profile.roleCode === 'distributor';
	}

	get isAdmin() {
		return this.cUser.profile.roleCode === 'admin';
	}

	get isSAPDistributor() {
		return this.cUser.profile.userCode === 'SAPDistributor';
	}

	toggleSidebar() {
		$('#sidebar').toggleClass('active');
		$('#navigation').toggleClass('navbar-collapes');
		$('#content').addClass('content-collapes');
	}

	keepOpen() {
		$('.keep-open li .price-list').on('click', function(e) {
			$('#inventory-aria').attr('aria-expanded', 'true');
			$('#inventory').addClass('show');
		});
		$('.keep-open li .active-customers').on('click', function(e) {
			$('#customers-aria').attr('aria-expanded', 'true');
			$('#customers').addClass('show');
		});
		$('.keep-open li .active-product').on('click', function(e) {
			$('#products-aria').attr('aria-expanded', 'true');
			$('#products').addClass('show');
		});
		$('.keep-open li .active-sale-route').on('click', function(e) {
			$('#sale-route-aria').attr('aria-expanded', 'true');
			$('#sale-route').addClass('show');
		});
		$('.keep-open li .active-kpi-target').on('click', function(e) {
			$('#kpi-target-aria').attr('aria-expanded', 'true');
			$('#kpi-target').addClass('show');
		});
		$('.keep-open li .active-user-management').on('click', function(e) {
			$('#user-management-aria').attr('aria-expanded', 'true');
			$('#user-management').addClass('show');
		});
		$('.keep-open li .active-app-setting').on('click', function(e) {
			$('#app-setting-aria').attr('aria-expanded', 'true');
			$('#app-setting').addClass('show');
		});
		$('.keep-open li .active-order').on('click', function(e) {
			$('#orders-aria').attr('aria-expanded', 'true');
			$('#orders').addClass('show');
		});
		$('.keep-open li .active-dashboard').on('click', function(e) {
			$('#dashboard-aria').attr('aria-expanded', 'true');
			$('#dashboard').addClass('show');
		});

		// add class show sub menu in report module
		$('.keep-open li .active-report').on('click', function(e) {
			$('#report-aria').attr('aria-expanded', 'true');
			$('#report').addClass('show');
		});

		// add class show sub menu in portal-config module
		$('.keep-open li .active-portal-config').on('click', function(e) {
			$('#portal-config-aria').attr('aria-expanded', 'true');
			$('#portal-config').addClass('show');
		});

		// add class show sub menu in portal-config module
		$('.keep-open li .active-survey').on('click', function(e) {
			$('#survey-aria').attr('aria-expanded', 'true');
			$('#survey').addClass('show');
		});

		$('a.dropdown-toggle').on('click', function() {
			if ($(this).attr('aria-expanded') === 'false') {
				$('ul.keep-open').removeClass('show');
				$('a.dropdown-toggle').attr('aria-expanded', 'false');
				$(this).attr('aria-expanded', 'true');
			}
		});
	}
}
