import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringNoDash'
})
export class StringNoDashPipe implements PipeTransform {

    transform(value: string, sign: string = '-'): string {
        sign = sign ? sign : '-';
        return value ? value.split(sign).join(' ') : '';
    }
}