import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-order-source-filter',
	template: `
		<app-select-filter 
			[hasIcon] = "hasIcon"
			bindLabel = "name"
			bindValue = "id"
			placeholder = "Select Order Source"
			label = "Order Source"
			[clearable]="false"
			[multiple] = "false"
			[items] = "options"
			[(value)] = value
			(valueChange)="valueChange.emit(value)"
		>
		</app-select-filter>
    `,
})

export class AppOrderSourceFilterComponent implements OnInit {
	@Input() multiple = false;
	@Input() value: any = null;
	@Output() valueChange = new EventEmitter();
	@Input() hasIcon = false;
	options = [
		{ id: '', name: 'All' },
		{ id: false, name: 'iOrder' },
		{ id: true, name: 'AYO' },
	]
	constructor() { }

	ngOnInit() { }
}