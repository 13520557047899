import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class SAPPullService {
	header = this.globalService.getHeader();

	constructor(private globalService: GlobalService, private http: HttpClient) { }

	/**** Address *****/
	pullRegion(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/geo/region`, {
			headers: this.header,
			params,
		});
	}

	pullCity(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/geo/cities`, {
			headers: this.header,
			params,
		});
	}

	pullDistrict(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/geo/districts`, {
			headers: this.header,
			params,
		});
	}

	pullCommune(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/geo/communes`, {
			headers: this.header,
			params,
		});
	}


	/***** Product *****/
	pullProductGroup(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/pull/product-group`, {
			headers: this.header,
			params,
		});
	}

	pullProductList(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/pull/product`, {
			headers: this.header,
			params,
		});
	}

	pullFlavor(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/product-flavor`, {
			headers: this.header,
			params,
		});
	}

	pullBrand(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/product-brand`, {
			headers: this.header,
			params,
		});
	}

	pullPackSize(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/pack-size`, {
			headers: this.header,
			params,
		});
	}


	/***** Promotion *****/
	pullPromotion(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/sap/soap/promotion/pull`, {
			headers: this.header,
			params,
		});
	}

	pullPromotionProductGroup(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/pull/promotion/product-group`, {
			headers: this.header,
			params,
		});
	}

	pullPromotionOutletGroup(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/outlet/pull/promotion-outlet-group`, {
			headers: this.header,
			params,
		});
	}

	/***** Other *****/
	pullCustomerGroup(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/outlet/type`, {
			headers: this.header,
			params,
		});
	}

	pullTax(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/tax/pull`, {
			headers: this.header,
			params,
		});
	}

	pullPrimaryDeliveryCloseOpen(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/orders/deliveries/close-open/pull`, {
			headers: this.header,
			params,
		});
	}

	pullCreditTerm(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/credit-term/pull`, {
			headers: this.header,
			params,
		});
	}

	pullOutletType(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/outlet/type`, {
			headers: this.header,
			params,
		});
	}

	pullPriceList(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/price/pull`, {
			headers: this.header,
			params,
		});
	}

	pullTradeChannel(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/orders/deliveries/pull-trade`, {
			headers: this.header,
			params,
		});
	}

	pullCustomerProductPrice(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/price`, {
			headers: this.header,
			params,
		});
	}

	pullSAPInventory(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v1/sap/soap/products/pull/stocks`, {
			headers: this.header,
			params,
		});
	}

	pullWarehouse(params?: any) {
		params = this.globalService.cleanParams(params);
		return this.http.get(this.globalService.url + `/v2/admin/sap/soap/warehouse/pull`, {
			headers: this.header,
			params,
		});
	}

	pullSAPManagement(params?: any) {
		return this.http.get(this.globalService.url + `/v1/sap/soap/orders/deliveries/management-reports/pull`, {
			headers: this.header,
			params: this.globalService.cleanParams(params),
		});
	}
}
