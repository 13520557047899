import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';

@Component({
	selector: 'app-brand',
	template: `
		<div class="position-relative d-flex justify-content-center">
			<div class="headline-filter-0">{{text}}</div>
			<div class="mini-filter">
				<ng-select [items]="items?.data" [(ngModel)]="selectedId" (ngModelChange)="selectedIdChange.emit(selectedId)" bindLabel="name" bindValue="id"
				[clearable]="false">
					<ng-template ng-label-tmp let-item="item">
						{{ item?.name | titlecase }}
					</ng-template>
				</ng-select>
			</div>
		</div>
	`
})
export class AppBrandComponent implements OnInit {
	@Input() text = 'Brand';
	@Input() selectedId: number;
	@Output() selectedIdChange = new EventEmitter();
	items = {
		data: [],
		limit: 100,
		total: 0,
	}

	constructor(private _productService: ProductsService,) { }

	ngOnInit() {
		this.getData();
	}

	getData() {
		if (this.items.data.length && this.items.data.length >= this.items.total) return;
		const params = {
			limit: this.items.limit,
			offset: this.items.data.length,
		};
		this._productService.brandFilter(params).subscribe((res: any) => {
			this.items.data = [...this.items.data, ...res.data];
			this.items.total = res.metadata.total;
			if (this.items.data.length) {
				if (this.items.data.length < this.items.total) this.getData();
				else this.items.data.unshift({
					brandCode: '',
					id: 0,
					logo: '',
					name: 'All',
					status: ''
				});
			}
		});
	}
}
