import { Component, OnInit } from '@angular/core';
@Component({
	selector: 'app-distributor',
	templateUrl: './distributor.component.html',
	styleUrls: ['./distributor.component.scss']
})
export class DistributorComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
