import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'orderStatus'
})
export class OrderStatus implements PipeTransform {
    data = [
        { id: 'new', name: 'New' },
		{ id: 'confirm', name: 'Confirmed' },
		{ id: 'delivery', name: 'Delivery' },
		{ id: 'deliver', name: 'Delivered' },
		{ id: 'delivered', name: 'Delivered' },
		{ id: 'undelivered', name: 'Undelivered' },
		{ id: 'cancel', name: 'Cancelled' },
		{ id: 'complete', name: 'Completed' },
    ]
	transform(value: string): any {
        const obj = this.data.find(o => o.id == value);
		return obj ? obj.name : value;
	}
}
