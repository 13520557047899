import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CompressImageService {
	constructor() {}

	compress(e: File, fileType, w, h) {
		return new Promise((resolve, reject) => {
			const width = w;
			const height = h;
			const fileName = e.name;
			const reader = new FileReader();
			reader.readAsDataURL(e);
			return (reader.onload = (event: any) => {
				const img = new Image();
				img.src = event.target.result;
				(img.onload = () => {
					const elem = document.createElement('canvas');
					elem.width = width;
					elem.height = height;
					const ctx = elem.getContext('2d');
					// img.width and img.height will contain the original dimensions
					ctx.drawImage(img, 0, 0, width, height);
					ctx.canvas.toBlob(
						blob => {
							const file = new File([blob], fileName, {
								type: fileType,
								lastModified: Date.now()
							});
							return resolve(file);
						},
						fileType,
						1
					);
				}),
					(reader.onerror = error => {
						return reject(error);
					});
			});
		});
	}
}
