import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-searchbox',
	templateUrl: './app-searchbox.component.html',
	styleUrls: ['./app-searchbox.component.scss']
})
export class AppSearchboxComponent implements OnInit {
	@Input() searchText;
	@Output() searchTextChange = new EventEmitter();
	@Output() inputText = new EventEmitter();
	searchChange;

	constructor() { }

	ngOnInit() {
		this.searchText = this.searchText ? this.searchText : '';
	}

	onSearch() {
		if (!this.searchChange) {
			Observable.create(observer => {
				this.searchChange = observer;
			})
				.pipe(
					debounceTime(500),
					distinctUntilChanged()
				)
				.subscribe(() => {
					this.searchText = this.searchText.trimLeft();
					this.inputText.next({
						searchText: this.searchText
					});
					this.searchTextChange.emit(this.searchText);
				});
		}
		this.searchChange.next(this.searchText);
	}
}
