import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-import-excel',
	templateUrl: './app-import-excel.component.html',
	styleUrls: ['./app-import-excel.component.scss']
})
export class AppImportExcelComponent implements OnInit {
	@Input() title = '';
	@Output() import = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	/**
	 * Get File from Template
	 * @param file
	 * @param type
	 */
	importF(file: FileList, type = 'any') {
		const data = new FormData();
		data.append('file', file[0]);
		data.append('type', type);
		this.import.next({
			file: data
		});
	}
}
