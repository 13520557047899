import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-status',
	templateUrl: './app-status.component.html',
})
export class AppStatusComponent implements OnInit {
	@Input() value = 'active';
	@Output() valueChange = new EventEmitter();
	@Output() onChange = new EventEmitter();
	statusList = [
		{ value: '', name: 'All' },
		{ value: 'active', name: 'Active' },
		{ value: 'inactive', name: 'Inactive' }
	];

	constructor() {}

	ngOnInit() {}

	onStatusChange(event) {
		this.onChange.emit(event);
		this.valueChange.emit(this.value);
	}
}
