import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
	selector: 'app-pagination',
	templateUrl: './app-pagination.component.html',
	styleUrls: ['./app-pagination.component.scss']
})
export class AppPaginationComponent implements OnInit {
	@Input() page = 1;
	@Input() limit = 10;
	@Input() totalItems = 0;

	@Output() pageChange = new EventEmitter();

	timeout: any;

	constructor() {}

	ngOnInit() {
		this.page = this.page && this.page >= 1 ? this.page : 1;
		this.limit = this.limit && this.limit >= 10 ? this.limit : 10;
		this.totalItems = this.totalItems ? this.totalItems : 0;
	}

	/// Change the number of limit.
	changeLimit() {
		/// Set new page's value and trigger action.
		this.page = 1;
		this.fireMyEvent();
	}

	/// Value of input box change.
	onKeying(evt) {
		const newValue = evt.target.value;
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (
				!newValue ||
				isNaN(newValue) ||
				newValue <= 0 ||
				newValue > this.getTotalPages() ||
				+newValue === this.page
			) {
				return;
			} else {
				/// Set new page's value and trigger action.
				this.page = +newValue;
				this.fireMyEvent();
			}
		}, 400);
	}

	/// Allow number only when keypress.
	isNumber(evt, allowDot: boolean = false) {
		evt = evt ? evt : window.event;
		const charCode = evt.which ? evt.which : evt.keyCode;
		if (allowDot) {
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				return false;
			}
		} else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
	}

	/// When unfocus page input box.
	unFocus(evt: any) {
		const target = evt.target;
		const newValue = target.value;
		if (
			!newValue ||
			isNaN(newValue) ||
			newValue <= 0 ||
			newValue > this.getTotalPages()
		) {
			target.value = this.page;
		} else {
			return;
		}
	}

	/// Get total number of page.
	getTotalPages() {
		if (this.totalItems < this.limit) {
			return 1;
		} else {
			let totalPage = this.totalItems / this.limit;
			const remain = this.totalItems % this.limit;

			if (remain > 0) {
				totalPage = Math.ceil(totalPage);
			}
			return totalPage;
		}
	}

	/// Go to the first of page.
	goToFirst() {
		if (this.totalItems < 1 || this.page === 1) {
			return;
		} else {
			/// Set new page's value and trigger action.
			this.page = 1;
			this.fireMyEvent();
		}
	}

	/// Go to the last of page.
	goToLast() {
		if (this.totalItems < 1 || this.page === this.getTotalPages()) {
			return;
		} else {
			/// Set new page's value and trigger action.
			this.page = this.getTotalPages();
			this.fireMyEvent();
		}
	}

	/// Go to previous page.
	goToPrevious() {
		if (this.page < 2) {
			return;
		} else {
			/// Set new page's value and trigger action.
			this.page--;
			this.fireMyEvent();
		}
	}

	/// Go to the next page.
	goToNext() {
		if (this.page >= this.getTotalPages()) {
			return;
		} else {
			/// Set new page's value and trigger action.
			this.page++;
			this.fireMyEvent();
		}
	}

	/// Test
	fireMyEvent() {
		this.pageChange.next({ page: this.page, limit: this.limit });
	}
}
