import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'app-start-end-date',
	templateUrl: './app-start-end-date.component.html',
	styleUrls: ['./app-start-end-date.component.scss']
})
export class AppStartEndDateComponent implements OnInit {
	maxDate: Date;
	maxStartDate: Date;
	minDate: Date;
	startDate: Date;
	endDate: Date;

	startedAt: string;
	endedAt: string;
	@Input() defaultStartDate: Date;
	@Input() defaultEndDate: Date;
	@Output() startDateChange = new EventEmitter();
	@Output() endDateChange = new EventEmitter();
	constructor() {
		this.maxDate = new Date();
		this.maxStartDate = new Date();
		if (this.defaultEndDate && this.defaultStartDate) {
			this.defaultStartDate = this.defaultStartDate;
			this.defaultEndDate = this.defaultEndDate;
		}
	}

	ngOnInit() {}

	/**
	 * Get Start Date Value
	 * @param value
	 */
	onStartDateChange(value: Date): void {
		if (value && String(value) !== 'Invalid Date') {
			this.startDate = value;
			this.minDate = this.startDate;
			this.startedAt = new Date(
				this.startDate.setHours(0, 0, 0, 0)
			).toISOString();

			this.startDateChange.next({
				startedAt: this.startedAt
			});
		}
	}

	/**
	 * Get End Date Value
	 * @param value
	 */
	onEndDateChange(value: Date): void {
		if (value && String(value) !== 'Invalid Date') {
			this.endDate = value;
			this.maxStartDate = this.endDate;
			this.endedAt = new Date(
				this.endDate.setHours(23, 59, 59, 999)
			).toISOString();

			this.endDateChange.next({
				endedAt: this.endedAt
			});
		}
	}
}
