import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Pipe({
	name: 'toImageUrl'
})
export class ToImageUrlPipe implements PipeTransform {
	constructor(private _globalService: GlobalService) {}

	transform(value: string): any {
		return value
			? value.includes('http://') || value.includes('https://')
				? value
				: this._globalService.url + '/v1/uploads/' + value
			: '';
	}
}
