import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { FilterSD } from 'src/app/shared/models/user';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
	selector: 'app-sales-director',
	templateUrl: './app-sales-director.component.html',
	styleUrls: ['./app-sales-director.component.scss']
})
export class AppSalesDirectorComponent implements OnInit {
	salesDirector: FilterSD[] = [];
	disabled = false;
	@Input() selectedSalesDirector = null;
	@Output() change = new EventEmitter();

	constructor(
		private dropdownFilter: DropdownFilterService,
		private cUser: CurrentUserService
	) {}

	ngOnInit() {
		if (this.cUser.profile) {
			this.cUser.profile.roleCode === 'sd' ||
			this.cUser.profile.roleCode === 'rsm' ||
			this.cUser.profile.roleCode === 'asm'
				? (this.disabled = true)
				: this.getSD();
		}
	}

	/**
	 * Get Sales Director Dropdown with API
	 */
	getSD(): void {
		this.dropdownFilter.getSD().subscribe((res: any) => {
			this.salesDirector = res.data;
			this.salesDirector.unshift({
				id: null,
				fullName: 'All',
				userCode: ''
			});
			this.selectedSalesDirector = this.selectedSalesDirector
				? this.selectedSalesDirector
				: this.salesDirector[0].id;
		});
	}

	/**
	 * Filter by Sale Director
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			salesDirectorId: event.id
		});
	}
}
