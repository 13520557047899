import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RegionalService {
    header = this.globalService.getHeader();
    url: string = this.globalService.url + '/v1/admin';
    regionalUrl = 'city-groups';

    constructor(private globalService: GlobalService, private http: HttpClient) { }

    getRegionals(params?: Object, deleteFields?: string[]) {
        return this.http.get(`${this.url}/${this.regionalUrl}`, {
            headers: this.globalService.getHeader(),
            params: this.globalService.cleanParams(params, deleteFields)
        });
    }

    getRegionalDetail(id: number) {
        return this.http.get(`${this.url}/${this.regionalUrl}/${id}`, { headers: this.globalService.getHeader() });
    }

    createRegional(body: Object) {
        return this.http.post(`${this.url}/${this.regionalUrl}`, body, { headers: this.globalService.getHeader() });
    }

    updateRegional(id: number, body: Object) {
        return this.http.put(`${this.url}/${this.regionalUrl}/${id}`, body, { headers: this.globalService.getHeader() });
    }

    manageRegional(id: number, body: Object) {
        return this.http.post(`${this.url}/${this.regionalUrl}/${id}/assigns`, body, { headers: this.globalService.getHeader() });
    }
}
