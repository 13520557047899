import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CustomersService {
    url: string = this.globalService.url;
    header = this.globalService.getHeader();
    constructor(private globalService: GlobalService, private http: HttpClient) { }

    pushToAYO() {
        return this.http.post(`${this.url}/v2/admin/ayo/customers/push_all`, {}, {
            headers: this.header
        });
    }

    /**
     * Push Payload to SAP
     * @param id
     */
    pushToSap(id) {
        return this.http.post(
            `${this.url}/v1/admin/outlets/re-push`,
            {},
            {
                headers: this.header,
                params: { id: id },
            }
        );
    }

    /**
     ** Get Father Code List
     * @param limit
     * @param offset
     */
    getAllFatherCode(limit, offset) {
        return this.http.get(
            `${this.url}/v1/admin/outlets/father-code?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
            }
        );
    }

    /**
     ** Get Warehouse List
     * @param limit
     * @param offset
     */
    getAllOutletTypes(limit, offset) {
        return this.http.get(
            `${this.url}/v2/admin/sap/soap/outlet/types?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
            }
        );
    }

    /**
     ** Get Warehouse List
     * @param limit
     * @param offset
     */
    getAllWarehouses(limit, offset) {
        return this.http.get(
            `${this.url}/v2/admin/sap/soap/warehouse?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
            }
        );
    }

    /**
     ** Get PriceList List
     * @param limit
     * @param offset
     */
    getAllPriceLists(limit, offset) {
        return this.http.get(
            `${this.url}/v2/admin/sap/soap/price?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
            }
        );
    }

    /**
     ** Get All Credit Terms
     * @param limit
     * @param offset
     */
    getAllCreditTerms(limit, offset) {
        return this.http.get(
            `${this.url}/v2/admin/sap/soap/credit-term?limit=${limit}&offset=${offset}&status=active`,
            {
                headers: this.header,
            }
        );
    }

    /**
     * Start Trade Channel Service
     */
    getTradeChannel(limit = 100, offset = 0) {
        const params = {
            limit: `${limit}`,
            offset: `${offset}`,
            status: 'active',
        };
        return this.http.get(`${this.url}/v1/admin/trade-channels`, {
            headers: this.header,
            params,
        });
    }

    /**
     * Start Trade Channel Service
     */
    getTradeChannelBySap(limit = 100, offset = 0, isSap = 0) {
        const params = {
            limit: `${limit}`,
            offset: `${offset}`,
            status: 'active',
            isSap: `${isSap}`,
        };
        return this.http.get(`${this.url}/v1/admin/trade-channels`, {
            headers: this.header,
            params,
        });
    }
    /**
     * End Trade Channel Service
     */

    /**
     * Start Distributor Service
     */
    getDistributors(limit, offset, param) {
        return this.http.get(
            `${this.url}/v1/admin/distributors?limit=${limit}&offset=${offset}${param}`,
            {
                headers: this.header,
            }
        );
    }
    getDistributor(id) {
        return this.http.get(`${this.url}/v1/admin/distributors/${id}`, {
            headers: this.header,
        });
    }
    createDistributor(body) {
        return this.http.post(`${this.url}/v1/admin/distributors`, body, {
            headers: this.header,
        });
    }
    updateDistributor(id, body) {
        return this.http.put(`${this.url}/v1/admin/distributors/${id}`, body, {
            headers: this.header,
        });
    }
    inactiveDistributor(id) {
        return this.http.delete(`${this.url}/v1/admin/distributors/${id}`, {
            headers: this.header,
        });
    }
    activeDistributor(id, body) {
        return this.http.put(
            `${this.url}/v1/admin/distributors/${id}/re-active`,
            body,
            {
                headers: this.header,
            }
        );
    }
    exportTemplateDistributor() {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/distributors?limit=0`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }

    exportAllDistributor(limit, status, search) {
        status ? (status = `&status=${status}`) : (status = '');
        search ? (search = `&search=${search}`) : (search = '');
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/distributors?limit=${limit}${status}${search}`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }
    importDistributor(files: FileList, type = 'any') {
        const data = new FormData();
        for (let i = 0; i <= files.length; i++) {
            data.append('file', files[i]);
        }
        data.append('type', type);

        return this.http.post(
            `${this.url}/v1/admin/master-data/imports/distributors`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
    }
    getCustomersInDistributor(id, params) {
        return this.http.get(`${this.url}/v1/admin/distributors/${id}/outlets`, {
            params,
            headers: this.header,
        });
    }

    /**
     * End Distributor Servcie
     */

    /**
     * Start Customers Servcie
     */
    getDistributorsInCustomer(limit = 100, offset = 0) {
        const params = {
            limit: `${limit}`,
            offset: `${offset}`,
            status: 'active',
            sortBy: 'name|asc'
        };
        return this.http.get(`${this.url}/v1/admin/distributors/drop-down`, {
            headers: this.header,
            params,
        });
    }
    getCustomers(limit, offset, params) {
        return this.http.get(
            `${this.url}/v1/admin/outlets?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
                params: params,
            }
        );
    }
    getCustomersOnManage(id, params) {
        return this.http.get(
            `${this.url
            }/v1/admin/outlets?outletGroupId=${id}&status=active`,
            {
                headers: this.header,
                params: this.globalService.cleanParams(params)
            }
        );
    }
    getCustomer(id) {
        return this.http.get(`${this.url}/v1/admin/outlets/${id}`, {
            headers: this.header,
        });
    }
    createCustomer(body) {
        return this.http.post(`${this.url}/v1/admin/outlets`, body, {
            headers: this.header,
        });
    }
    updateCustomer(id, body) {
        return this.http.put(`${this.url}/v1/admin/outlets/${id}`, body, {
            headers: this.header,
        });
    }
    inactiveCustomer(id) {
        return this.http.delete(`${this.url}/v1/admin/outlets/${id}`, {
            headers: this.header,
        });
    }
    activeCustomer(id, body) {
        return this.http.put(`${this.url}/v1/admin/outlets/${id}/re-active`, body, {
            headers: this.header,
        });
    }
    exportTemplate() {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/outlets?limit=0`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }
    exportAll(limit, param) {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/outlets`,
            {
                headers: this.header,
                params: this.globalService.cleanParams(param),
                responseType: 'blob',
            }
        );
    }

    /**
     * Export Error Logs
     */
    exportErrorLogs() {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/outlets/errors`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }

    /**
     * Import Customer Master Data
     * @param data
     */
    importCustomer(data) {
        return this.http.post(
            `${this.url}/v1/admin/master-data/imports/outlets`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
    }
    /**
     * End Customer Service
     */

    /**
     * Start Customer Location Service
     */

    getCustomersLocations(limit, offset, param) {
        return this.http.get(
            `${this.url}/v1/admin/outlets/locations?limit=${limit}&offset=${offset}${param}`,
            {
                headers: this.header,
            }
        );
    }

    updateCustomerLocation(body) {
        return this.http.put(`${this.url}/v1/admin/outlets/locations`, body, {
            headers: this.header,
        });
    }

    exportTemplateLocal() {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/locations?limit=0`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }

    exportAllLocal(limit, param) {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/locations?limit=${limit}${param}`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }

    /**
     * Import Customer Location Master Data
     * @param data
     */
    importCustomerLocal(data) {
        return this.http.post(
            `${this.url}/v1/admin/master-data/imports/locations`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
    }

    /**
     * End Customer Location Service
     */

    /**
     * Start Customer Group Service
     */
    getCustomersGroups(limit, offset, search, status) {
        search ? (search = `&search=${search}`) : (search = '');
        status ? (status = `&status=${status}`) : (status = '');
        return this.http.get(
            `${this.url}/v1/admin/outlet-groups?limit=${limit}&offset=${offset}${search}${status}`,
            {
                headers: this.header,
            }
        );
    }
    getCustomersGroup(id) {
        return this.http.get(`${this.url}/v1/admin/outlet-groups/${id}`, {
            headers: this.header,
        });
    }

    createCustomerGroups(body) {
        return this.http.post(`${this.url}/v1/admin/outlet-groups`, body, {
            headers: this.header,
        });
    }

    editCustomerGroups(id, body) {
        return this.http.put(`${this.url}/v1/admin/outlet-groups/${id}`, body, {
            headers: this.header,
        });
    }

    inactiveCustomerGroup(id) {
        return this.http.delete(`${this.url}/v1/admin/outlet-groups/${id}`, {
            headers: this.header,
        });
    }
    activeCustomerGroup(id, body) {
        return this.http.put(
            `${this.url}/v1/admin/outlet-groups/${id}/re-active`,
            body,
            {
                headers: this.header,
            }
        );
    }
    getCustomersInGroup(id, limit, offset) {
        return this.http.get(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets?limit=${limit}&offset=${offset}`,
            {
                headers: this.header,
            }
        );
    }
    addCustomersToGroup(id, body) {
        return this.http.put(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets/assigns`,
            body,
            {
                headers: this.header,
            }
        );
    }
    removeCustomersFromGroup(id, body) {
        return this.http.put(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets/removes`,
            body,
            {
                headers: this.header,
            }
        );
    }

    assignRemoveCustomersInGroup(id, body: { assignsIds?: number[], removesIds?: number[] }) {
        return this.http.put(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets/assigns-removes`,
            body,
            {
                headers: this.header,
            }
        );
    }

    assignAllCustomersToGroup(id, body: { excludedIds?: number[], outletIds?: number[] }) {
        return this.http.post(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets/assigns-all`,
            body,
            {
                headers: this.header,
            }
        );
    }

    removeAllCustomersFromGroup(id, body: { excludedIds?: number[], outletIds?: number[] }) {
        return this.http.put(
            `${this.url}/v1/admin/outlet-groups/${id}/outlets/removes-all`,
            body,
            {
                headers: this.header,
            }
        );
    }

    exportCustomerGroupMemberTemplate() {
        return this.http.get(
            `${this.url}/v1/admin/master-data/exports/outlet-group-outlet-template`,
            {
                headers: this.header,
                responseType: 'blob',
            }
        );
    }

    importCustomerGroupMember(groupId: number, data) {
        return this.http.post(
            `${this.url}/v1/admin/outlet-groups/${groupId}/outlets/assigns-import`,
            data,
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
    }
}
