import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { KPITargetProduct } from '../../models/kpi-target';
declare let $: any;

@Component({
	selector: 'app-pick-product-target-modal',
	templateUrl: './app-pick-product-target-modal.component.html',
	styleUrls: ['./app-pick-product-target-modal.style.scss']
})
export class AppPickProductTargetModalComponent implements OnInit {
	@Input() id = 'app-pick-product-target-modal';
	@Input() parentSelectedProds: KPITargetProduct[] = [];
	@Output() onConfirm = new EventEmitter();
	products: KPITargetProduct[] = [];
	selectedProds: KPITargetProduct[] = [];
	metadata = {
		limit: 100,
		offset: 0,
		total: 0,
		loading: false,
	};
	filterData = {
		brandId: null,
		flavorId: null,
		packSizeId: null,
		search: '',
	};

	constructor(private productService: ProductsService) { }

	ngOnInit() {
		$(document).off('show.bs.modal', `#${this.id}`)
			.on('show.bs.modal', `#${this.id}`, () => {
				console.log(this.selectedProds);
				
				this.selectedProds = JSON.parse(JSON.stringify(this.parentSelectedProds));
			});
		this.getProducts();
	}

	getProducts() {
		const params = {
			status: 'active',
			search: this.filterData.search,
			limit: this.metadata.limit,
			offset: this.products.length,
			brandId: this.filterData.brandId,
			flavorId: this.filterData.flavorId,
			packSizeId: this.filterData.packSizeId
		};
		this.metadata.loading = true;
		this.productService
			.getProducts(params)
			.subscribe(
				(res: any) => {
					this.products = [...this.products, ...res.data];
					this.metadata.total = res.metadata.total;
					this.metadata.loading = false;
				},
				(err: any) => { this.metadata.loading = false; }
			);
	}

	filter() {
		this.products = [];
		this.getProducts();
	}

	confirm() {
		this.onConfirm.emit(this.selectedProds);
	}

	scrollProduct(event) {
		const target = event.target;
		if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
			if (this.products && this.products.length && this.products.length < this.metadata.total) {
				this.getProducts();
			}
		}
	}

	toggleCheck(product: KPITargetProduct) {
		const prod = JSON.parse(JSON.stringify(product));
		const index = this.selectedProds.findIndex(p => p.id === prod.id);
		if (index >= 0) {
			this.selectedProds.splice(index, 1);
		} else {
			this.selectedProds.push(prod);
		}
	}

	isCheck(productId: number) {
		return this.selectedProds.some(p => p.id === productId);
	}
}
