import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TradeChannel } from 'src/app/shared/models/trade-channel';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';

@Component({
	selector: 'app-trade-channel',
	templateUrl: './app-trade-channel.component.html',
	styleUrls: ['./app-trade-channel.component.scss']
})
export class AppTradeChannelComponent implements OnInit {
	tradeChannels: TradeChannel[];
	selectedTradeChannels: number;
	@Input() tradeChannelId = null;
	@Output() change = new EventEmitter();

	constructor(private dropdownFilter: DropdownFilterService) {}

	ngOnInit() {
		this.getTradeChannels();
	}

	/**
	 * Get Trade channels Dropdown
	 */
	getTradeChannels() {
		this.dropdownFilter.getTradeChannel().subscribe((res: any) => {
			this.tradeChannels = res.data;
			this.tradeChannels.unshift({
				id: null,
				name: 'All',
				code: null,
				description: null
			});
			if (this.tradeChannelId) {
				this.selectedTradeChannels = this.tradeChannelId;
			} else {
				this.selectedTradeChannels = this.tradeChannels[0].id;
			}
		});
	}

	/**
	 * Filter by Sale Director
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			tradeChannelId: event.id
		});
	}
}
