import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { FilterRSM } from 'src/app/shared/models/user';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
	selector: 'app-rsm',
	templateUrl: './app-rsm.component.html',
	styleUrls: ['./app-rsm.component.scss']
})
export class AppRsmComponent implements OnInit {
	rsm: FilterRSM[];
	@Input() selectedRSM = null;
	disabled = false;
	@Output() change = new EventEmitter();

	constructor(
		private dropdownFilter: DropdownFilterService,
		private cUser: CurrentUserService
	) {}

	ngOnInit() {
		if (this.cUser.profile) {
			if (this.cUser.profile.roleCode === 'sd') {
				this.getRSM(this.cUser.profile.id);
			} else if (
				this.cUser.profile.roleCode === 'rsm' ||
				this.cUser.profile.roleCode === 'asm'
			) {
				this.disabled = true;
			} else {
				this.getRSM('');
			}
		}
	}

	/**
	 * Get RSM Dropdown with API
	 */
	getRSM(id): void {
		let param = '';
		if (id) param = `&managerId=${id}`;
		this.dropdownFilter.getRSMOrASM('rsm', param).subscribe((res: any) => {
			this.rsm = res.data;
			this.rsm.unshift({
				id: null,
				fullName: 'All',
				userCode: ''
			});
			this.selectedRSM = this.selectedRSM ? this.selectedRSM : this.rsm[0].id;
		});
	}

	/**
	 * Filter by RSM
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			rsmId: event.id
		});
	}
}
