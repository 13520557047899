import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-table-filter',
	template: `
		<div class="position-relative">
			<div class="headline-filter-3">{{label}}</div>
			<div class="mini-filter">
				<ng-select [items]="items" (change)="onStatusChange($event)" [(ngModel)]="value" bindLabel="name"
					bindValue="value" [clearable]="false">
					<ng-template ng-label-tmp let-item="item">
						{{ item.name | titlecase }}
					</ng-template>
				</ng-select>
			</div>
		</div>
	`,
})
export class AppTableFilterComponent implements OnInit {
	@Input() label = '';
	@Input() value = '';
	@Output() valueChange = new EventEmitter();
	@Output() onChange = new EventEmitter();
	@Input() items = [];

	constructor() { }

	ngOnInit() { }

	onStatusChange(event) {
		this.onChange.emit(event);
		this.valueChange.emit(this.value);
	}
}
