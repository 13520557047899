import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RegionalService } from 'src/app/services/portal-config/regional.service';

@Component({
	selector: 'app-regional-table-filter',
	template: `
		<div class="position-relative">
			<div class="headline-filter-3">Regional</div>
			<div class="mini-filter">
				<ng-select [multiple]="multiple" [items]="metadata?.data" [(ngModel)]="value" (change)="valueChange.emit(value)" bindLabel="name" bindValue="id"
				[clearable]="false">
					<ng-template ng-label-tmp let-item="item">
						{{ item.name }}
					</ng-template>
				</ng-select>
			</div>
		</div>
    `,
})

export class AppRegionalTableFilterComponent implements OnInit {
	@Input() multiple = false;
	@Input() value: number = null;
	@Output() valueChange = new EventEmitter();
	metadata = {
		data: [],
		limit: 100,
		total: 0,
		search: '',
	}
	constructor(private regionalService: RegionalService) { }

	ngOnInit() {
		this.getRegionals();
	}

	getRegionals() {
		let mtd = this.metadata;
		const params = {};
		if (!mtd.data.length || mtd.data.length < mtd.total) {
			this.regionalService
				.getRegionals(params)
				.subscribe((res: any) => {
					const mappedData = (res.data || []).map(d => {
						return {
							id: d.id,
							code: d.code,
							name: d.code,
						}
					})
					mtd.data = [...mtd.data, ...mappedData];
					mtd.total = res.metadata.total;

					if (mtd.data.length && mtd.data.length < mtd.total) this.getRegionals();
					else {
						if (!this.multiple) {
							mtd.data.unshift({
								id: '',
								code: '',
								name: 'All',
							});
							this.value = this.value || mtd.data[0].id;
						}
					}
				});
		}

	}

	refresh() {
		/***** wait until value is successfully transfer *****/
		setTimeout(() => {
			this.value = null;
			this.metadata.data = [];
			this.getRegionals();
		}, 500);
	}
}