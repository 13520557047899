import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
declare let $: any;

@Component({
    selector: 'app-date-filter',
    template: `
		<div class="btn-order d-inline-flex align-items-center date-group mr-3 mb-10">
			<span class="text-nowrap">{{label}}</span>
            <input type="text"
                [placeholder] = "placeholder"
                [bsConfig]="config"
                [minDate]="minDate"
                [maxDate]="maxDate" 
                (ngModelChange)="onValueChange($event)" 
                [ngModel]="value"
                [daysDisabled]="daysDisabled" 
                [disabled]="isDisabled"
                class="form-control input-date mx-2" 
                bsDatepicker 
                autocomplete="off">
            <img src="/assets/images/icons/calender.png" class="calender-icon" alt="calender">
        </div>
    `,
})

export class AppDateFilterComponent implements OnInit {
    @Input() placeholder = '';
    @Input() label = '';
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() format = '';
    @Input() minMode = '';
    @Input() isDisabled = false;
    @Input() daysDisabled: [];
    @Output() onChange = new EventEmitter();
    @Input() value: Date;
    @Output() valueChange = new EventEmitter();
    @Input() showWeekNumbers = false;
    @Input() selectWeek = false;
    config = {};
    interval;

    constructor() { }

    ngOnInit() {
        this.format = this.format ? this.format : (this.selectWeek ? 'YYYY-wo' : this.minMode == 'month' ? 'MMM-YYYY' : 'DD-MMM-YYYY');
        this.config = Object.assign({}, {
            containerClass: this.selectWeek ? 'theme-green week-selection' : 'theme-green',
            isAnimated: true,
            customTodayClass: 'custom-today-class',
            adaptivePosition: true,
            dateInputFormat: this.format,
            minMode: this.minMode,
            showWeekNumbers: this.selectWeek || this.showWeekNumbers,
        });
        this.selectWeekStyle();
    }
    ngOnDestroy() {
        if (this.interval) clearInterval(this.interval);
    }

    selectWeekStyle() {
        /***** the style, ".week-selection" locate in _custom.scss file *****/
        this.interval = setInterval(() => {
            $('.theme-green.week-selection .bs-datepicker-body table.days td span.selected').closest('tr').addClass('selected');
        }, 100);
    }

    onValueChange(e) {
        if (e && e.toString().toLowerCase().includes('invalid'))
            e = new Date();
        this.valueChange.emit(e);
        this.onChange.emit(e);
    }
}