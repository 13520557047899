import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomersService } from 'src/app/services/customers.service';
import { DistributorDropdown } from 'src/app/shared/models/distributor';

@Component({
	selector: 'app-distributor',
	templateUrl: './app-distributor.component.html',
	styleUrls: ['./app-distributor.component.scss']
})
export class AppDistributorComponent implements OnInit {
	distributors: DistributorDropdown[];
	newData: any[];
	oldData: any[] = [];
	offset = 0;
	limit = 100;
	totals: number;
	selectedDistrib: number;
	numberOfItemsFromEndBeforeFetchingMore = 5;
	addNameAll = true;
	@Input() distributorId = null;
	@Output() distrChange = new EventEmitter();

	constructor(private customerService: CustomersService) {}

	ngOnInit() {
		this.getDistributors();
	}
	/**
	 * Get Distributor Dropdown
	 */
	getDistributors() {
		this.customerService
			.getDistributorsInCustomer(this.limit, this.offset)
			.subscribe((res: any) => {
				this.distributors = res.data;
				this.totals = res.metadata.total;

				this.newData = [];
				// check old data and push to filter
				if (this.oldData.length) {
					this.oldData.forEach(i => {
						this.newData.push({
							distributorCode: i.distributorCode,
							distributorId: i.distributorId,
							fullName: i.fullName
						});
					});
				}
				// push new data to filter
				if (this.distributors.length) {
					this.distributors.forEach(i => {
						this.newData.push({
							distributorCode: i.distributorCode,
							distributorId: i.distributorId,
							fullName: i.fullName
						});
					});
				}

				// set default value in filter
				if (this.addNameAll) {
					this.newData.unshift({
						distributorCode: '',
						distributorId: null,
						fullName: 'All'
					});
				}
				this.addNameAll = false;

				// store old data
				this.oldData = [];
				if (this.newData.length) {
					this.newData.forEach(i => {
						this.oldData.push({
							distributorCode: i.distributorCode,
							distributorId: i.distributorId,
							fullName: i.fullName
						});
					});
				}

				// set value in filter
				if (this.distributorId) {
					this.selectedDistrib = this.distributorId;
				} else if (this.newData.length) {
					this.selectedDistrib = this.newData[0].distributorId;
				}
			});
	}

	/*********************************
	 * Start Get Distributor on Scroll *
	 *********************************/
	onScrollToEnd() {
		this.fetchMore();
	}

	onScroll({ end }) {
		if (this.newData.length >= this.totals) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.totals) {
			this.fetchMore();
		}
	}

	private fetchMore() {
		this.offset = this.offset + this.limit;
		if (this.newData.length >= this.totals) {
			return;
		}
		this.getDistributors();
	}
	/**********************************
	 ** End Get Distributor on Scroll  **
	 **********************************/

	/**
	 * Filter Customers by Sales
	 * @param event
	 */
	onDistrChange(event) {
		this.distrChange.next({
			distributorId: event.distributorId
		});
	}
}
