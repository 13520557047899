import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { District } from 'src/app/shared/models/user';

@Component({
	selector: 'app-district',
	templateUrl: './app-district.component.html',
	styleUrls: ['./app-district.component.scss'],
})
export class AppDistrictComponent implements OnInit {
	districts: District[];
	districtsFilter: District[] = [];
	districtsOld: District[] = [];
	selectedDistrict: number;
	limit = 100;
	offset = 0;
	totals: number;
	firstRow = true;
	numberOfItemsFromEndBeforeFetchingMore = 10;
	@Input() districtId = null;
	@Output() change = new EventEmitter();

	constructor(private dropdownFilter: DropdownFilterService) {}

	ngOnInit() {
		this.getDistricts('');
	}

	/**
	 * Get districts Dropdown with API
	 */
	getDistricts(id): void {
		let param = '';
		if (id) {
			param = `&cityId=${id}`;
			this.offset = 0;
		}
		if (id === null) {
			this.offset = 0;
		}
		this.dropdownFilter
			.getDistricts(param, this.limit, this.offset)
			.subscribe((res: any) => {
				this.districts = res.data;
				this.totals = res.metadata.total;

				if (!param) {
					this.districtsFilter = [];
					// check old data and push to filter
					if (this.districtsOld.length) {
						this.districtsOld.forEach((i) => {
							this.districtsFilter.push({
								id: i.id,
								name: i.name,
								cityId: i.cityId,
								code: i.code,
								sapDistrictCode: i.sapDistrictCode,
							});
						});
					}
					// push new data to filter
					if (this.districts.length) {
						this.districts.forEach((i) => {
							this.districtsFilter.push({
								id: i.id,
								name: i.name,
								cityId: i.cityId,
								code: i.code,
								sapDistrictCode: i.sapDistrictCode,
							});
						});
					}
					// set default value in filter
					if (this.firstRow) {
						this.districtsFilter.unshift({
							id: null,
							name: 'All',
							cityId: null,
							code: undefined,
							sapDistrictCode: undefined,
						});
					}
					this.firstRow = false;
					// store old data
					this.districtsOld = [];
					if (this.districtsFilter.length) {
						this.districtsFilter.forEach((i) => {
							this.districtsOld.push({
								id: i.id,
								name: i.name,
								cityId: i.cityId,
								code: i.code,
								sapDistrictCode: i.sapDistrictCode,
							});
						});
					}
				} else {
					this.districtsFilter = this.districts;
					this.districtsOld = [];
					this.firstRow = true;
					this.districtsFilter.unshift({
						id: null,
						name: 'All',
						cityId: null,
						code: undefined,
						sapDistrictCode: undefined,
					});
				}
				// set value in filter
				if (this.districtId) {
					this.selectedDistrict = this.districtId;
				} else if (this.districts.length) {
					this.selectedDistrict = this.districtsFilter[0].id;
				}
			});
	}

	/*********************************
	 * Start Get Sale Reps on Scroll *
	 *********************************/
	onScrollToEnd() {
		this.fetchMore();
	}

	onScroll({ end }) {
		if (this.districtsFilter.length >= this.totals) {
			return;
		}

		if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.totals) {
			this.fetchMore();
		}
	}

	private fetchMore() {
		this.offset = this.offset + this.limit;
		if (this.districtsFilter.length >= this.totals) {
			return;
		}
		this.getDistricts('');
	}
	/**********************************
	 ** End Get Sale Reps on Scroll  **
	 **********************************/

	/**
	 * Filter by City
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			districtId: event.id,
		});
	}
}
