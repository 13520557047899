import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { LoginService } from "./login.service";
declare let $: any;

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

    constructor(public router: Router, private loginService: LoginService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error) => {
                const {statusCode, message} = error.error;
                
                /*==================== Multiple Device Login Error ====================*/
                if (statusCode && message && statusCode === 403 && message.toLowerCase().includes('from another device')) 
                { 
                    $('#multi-app-login-modal').modal('show');
                    this.loginService.logout();
                }

                return throwError(error);
            })
        )
    }
}