import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ProductsService {
	header = this.globalService.getHeader();
	url: string = this.globalService.url + '/v1/admin';
	rootUrl = this.globalService.url;

	constructor(private globalService: GlobalService, private http: HttpClient) { }

	/**
	 * Get, Add, Update and filter Brand
	 * @param search
	 * @param limit
	 * @param offset
	 */
	getProduct(
		keyword?: string,
		limit?: number,
		offset?: number,
		brandId?: number,
		packSizeId?: number,
		flavorId?: number,
		status?: string,
		groupId?: string,
		brandIds?: string,
	) {
		const search = keyword ? '&search=' + keyword : '';
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const brand = brandId ? '&brandId=' + brandId : '';
		const packSize = packSizeId ? '&packSizeId=' + packSizeId : '';
		const flavor = flavorId ? '&flavorId=' + flavorId : '';
		const state = status ? '&status=' + status : '';
		const grId = groupId ? '&groupId=' + groupId : '';
		const brandIdArr = brandIds ? '&brandIds=' + brandIds : '';
		return this.http.get(
			`${this.url}/products` +
			lim +
			search +
			off +
			brand +
			packSize +
			flavor +
			state +
			grId + 
			brandIdArr,
			{ headers: this.header }
		);
	}

	getProducts(params) {
		return this.http.get(`${this.url}/products`,
			{ 
				headers: this.header,
				params: this.globalService.cleanParams(params)
			}
		);
	}

	postProduct(body) {
		return this.http.post(`${this.url}/products`, body, {
			headers: this.header
		});
	}

	getProductDetail(id) {
		return this.http.get(`${this.url}/products/${id}`, {
			headers: this.header
		});
	}

	editProduct(id, body) {
		return this.http.put(`${this.url}/products/${id}`, body, {
			headers: this.header
		});
	}

	toggleProduct(id) {
		return this.http.post(`${this.url}/products/${id}/toggle`, '', {
			headers: this.header
		});
	}

	getExportProductTempate() {
		return this.http.get(`${this.url}/products/export-template`, {
			headers: this.header,
			responseType: 'blob'
		});
	}

	getExportProduct(
		keyword?: string,
		limit?: number,
		offset?: number,
		brandId?: number,
		packSizeId?: number,
		flavorId?: number,
		status?: string,
		categoryId?: number
	) {
		const search = keyword ? '&search=' + keyword : '';
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const brand = brandId ? '&brandId=' + brandId : '';
		const category = categoryId ? '&categoryId=' + categoryId : '';
		const packSize = packSizeId ? '&packSizeId=' + packSizeId : '';
		const flavor = flavorId ? '&flavorId=' + flavorId : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(
			`${this.url}/products/export` +
			lim +
			search +
			off +
			brand +
			category +
			packSize +
			flavor +
			state,
			{
				headers: this.header,
				responseType: 'blob'
			}
		);
	}

	postImportProduct(data) {
		return this.http.post(`${this.url}/master-data/imports/products`, data, {
			headers: {
				Authorization: localStorage.getItem('token')
			}
		});
	}

	postMultiProduct(body) {
		return this.http.post(`${this.url}/products/price`, body, {
			headers: this.header
		});
	}

	updateProductLogo(id, body) {
		return this.http.put(`${this.url}/products/${id}/logo`, body, {
			headers: this.header
		});
	}

	brandFilter(params?: Object) {
		return this.http.get(`${this.url}/brands?status=active`, {
			headers: this.header, 
			params: this.globalService.cleanParams(params)
		});
	}

	flavorFilter(limit = 100, offset = 0) {
		const params = {
			limit: `${limit}`,
			offset: `${offset}`,
			status: 'active'
		};
		return this.http.get(`${this.url}/flavors`, {
			headers: this.header, params
		});
	}

	packSizeFilter(limit = 100, offset = 0) {
		const params = {
			limit: `${limit}`,
			offset: `${offset}`,
			status: 'active'
		};
		return this.http.get(`${this.url}/packsizes`, {
			headers: this.header, params
		});
	}

	warehouseFilter(limit = 100, offset = 0) {
		const params = {
			limit: `${limit}`,
			offset: `${offset}`,
			status: 'active'
		};
		return this.http.get(`${this.rootUrl}/v2/admin/sap/soap/warehouse`, {
			headers: this.header, params
		});
	}

    pushToAYO() {
		return this.http.post(`${this.rootUrl}/v2/admin/ayo/products/push_all`, {}, {
			headers: this.header
		});
	}

	/**
	 * Get, Add, Update and filter Brand
	 * @param search
	 * @param limit
	 * @param offset
	 */
	getBrand(search?: string, limit?: number, offset?: number, status?: string) {
		const lim = limit ? 'limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(`${this.url}/brands?` + lim + off + keyword + state, {
			headers: this.header
		});
	}

	postBrand(body) {
		return this.http.post(`${this.url}/brands`, body, {
			headers: this.header
		});
	}

	editBrand(id, body) {
		return this.http.put(`${this.url}/brands/${id}`, body, {
			headers: this.header
		});
	}

	getBrandDetail(id) {
		return this.http.get(`${this.url}/brands/${id}`, {
			headers: this.header
		});
	}

	toggleBrand(id) {
		return this.http.post(`${this.url}/brands/${id}/toggle`, '', {
			headers: this.header
		});
	}

	updateBrandLogo(id, body) {
		return this.http.put(`${this.url}/brands/${id}/logo`, body, {
			headers: this.header
		});
	}

	getExportBrand(
		search?: string,
		limit?: number,
		offset?: number,
		status?: string
	) {
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(
			`${this.url}/brands/export` + lim + off + keyword + state,
			{
				headers: this.header,
				responseType: 'blob'
			}
		);
	}

	/**
	 * Get, Add, Update and filter Flavor
	 * @param search
	 * @param limit
	 * @param offset
	 */
	getFlavour(
		search?: string,
		limit?: number,
		offset?: number,
		status?: string
	) {
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(`${this.url}/flavors` + lim + off + keyword + state, {
			headers: this.header
		});
	}

	postFlavor(body) {
		return this.http.post(`${this.url}/flavors`, body, {
			headers: this.header
		});
	}

	editFlavor(id, body) {
		return this.http.put(`${this.url}/flavors/${id}`, body, {
			headers: this.header
		});
	}

	getDetailFlavor(id) {
		return this.http.get(`${this.url}/flavors/${id}`, {
			headers: this.header
		});
	}

	deleteFlavor(id) {
		return this.http.delete(`${this.url}/flavors/${id}`, {
			headers: this.header
		});
	}

	toggleFlavor(id) {
		return this.http.post(`${this.url}/flavors/${id}/toggle`, '', {
			headers: this.header
		});
	}

	getExportFlavor(
		search?: string,
		limit?: number,
		offset?: number,
		status?: string
	) {
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(
			`${this.url}/flavors/export` + lim + off + keyword + state,
			{
				headers: this.header,
				responseType: 'blob'
			}
		);
	}

	/**
	 * Get, Add, Update and filter Pack Size
	 * @param search
	 * @param limit
	 * @param offset
	 */
	getPackSize(
		search?: string,
		limit?: number,
		offset?: number,
		status?: string
	) {
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(
			`${this.url}/packsizes` + lim + off + keyword + state,
			{
				headers: this.header
			}
		);
	}

	postPackSize(body) {
		return this.http.post(`${this.url}/packsizes`, body, {
			headers: this.header
		});
	}

	editPackSize(id, body) {
		return this.http.put(`${this.url}/packsizes/${id}`, body, {
			headers: this.header
		});
	}

	getDetailPackSize(id) {
		return this.http.get(`${this.url}/packsizes/${id}`, {
			headers: this.header
		});
	}

	getExportPackSize(
		search?: string,
		limit?: number,
		offset?: number,
		status?: string
	) {
		const lim = limit ? '?limit=' + limit : '';
		const off = offset ? '&offset=' + offset : '';
		const keyword = search ? '&search=' + search : '';
		const state = status ? '&status=' + status : '';
		return this.http.get(
			`${this.url}/packsizes/export` + lim + off + keyword + state,
			{
				headers: this.header,
				responseType: 'blob'
			}
		);
	}
	// uploadProductIcont(body) {
	// 	return this.http.post(`${this.url}/v1/uploads`, body, {
	// 		headers: this.header
	// 	});
	// }

	/**
	 * Product Group
	 */
	getProductGroup(query: string) {
		return this.http.get(`${this.url}/product-group${query}`, {
			headers: this.header
		});
	}

	getProGroupMember(id: number) {
		return this.http.get(`${this.url}/product-group/${id}/products`, {
			headers: this.header
		});
	}

	manageGroupMember(id: number, obj: any) {
		return this.http.put(
			`${this.url}/product-group/${id}/product/assign`,
			obj,
			{
				headers: this.header
			}
		);
	}

	createProductGroup(obj: any) {
		return this.http.post(`${this.url}/product-group`, obj, {
			headers: this.header
		});
	}

	getOneProductGroup(id: number) {
		return this.http.get(`${this.url}/product-group/${id}/detail`, {
			headers: this.header
		});
	}

	updateProductGroup(id: number, obj: any) {
		return this.http.put(`${this.url}/product-group/${id}`, obj, {
			headers: this.header
		});
	}

	toggleGroupStatus(id: number) {
		return this.http.post(
			`${this.url}/product-group/${id}/toggle`,
			{},
			{
				headers: this.header
			}
		);
	}

	/**
	 * End Product Group.
	 */
}
