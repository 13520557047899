import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';

@Component({
	selector: 'app-red-customers',
	template: `
		<div class="position-relative">
			<div class="headline-filter-2">Red Customer</div>
			<div class="mini-filter">
				<ng-select
					[items]="redCustomers"
					[(ngModel)]="selectedRed"
					(change)="onRedCustomerChange($event)"
					bindLabel="redCustomer"
					bindValue="redCustomer"
					[clearable]="false"
					[virtualScroll]="true"
				>
					<ng-template ng-label-tmp let-item="item">
						{{ item.redCustomer | titlecase }}
					</ng-template>
				</ng-select>
			</div>
		</div>
	`
})
export class AppRedCustomersComponent implements OnInit {
	public redCustomers: any[] = [];
	public selectedRed = '';
	@Input() redCustomer: string;
	@Output() change = new EventEmitter();

	constructor(private dropdownFilter: DropdownFilterService) {}

	ngOnInit() {
		this.getRedCustomerType();
	}

	onRedCustomerChange(e) {
		this.change.next({
			redCustomer: e.redCustomer
		});
	}

	/**
	 * Get Red Customer Type
	 */
	getRedCustomerType() {
		this.dropdownFilter.getRedCustomerType().subscribe((res: any) => {
			this.redCustomers = res.data;
			this.redCustomers.unshift({
				redCustomer: 'all'
			});
			this.selectedRed = this.redCustomer
				? this.redCustomer
				: this.redCustomers[0].redCustomer;
		});
	}
}
