import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-block-loading',
	styles: [],
	template: `
        <div class="loading-block d-flex justify-content-center align-items-center w-100 h-100"  *ngIf="loading">
				<div class="lds-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
		</div>
    `
})
export class AppBlockLoadingComponent implements OnInit {
	@Input() loading = false;

	constructor() { }

	ngOnInit() { }
}
