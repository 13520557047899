import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
    selector: 'app-select-filter',
    template: `
		<div class="filter-with-label d-inline-flex">
			<span class="mr-2"><img *ngIf="hasIcon" src="/assets/images/icons/Filter-icon.png" class="filter-img mr-2" />{{label}}</span>
			<ng-select 
				#app_select_filter
				[placeholder]="placeholder"
				appendTo="{{ isAppend ? '.' + parentId + '-append' : '' }}"
				[disabled]="disabled"
				[multiple]="multiple"
				[closeOnSelect]="!multiple"
				[clearable]="clearable"
				[searchable]="searchable"
				[items]="items"
				[bindLabel]="bindLabel"
				[bindValue]="bindValue"
				[(ngModel)]="value"
				(change)="selectChange($event)"
				(open)="searchable ? (app_select_filter['searchable'] = true) : (app_select_filter['searchable'] = false)"
				(close)="app_select_filter['searchable'] = false; selectClose()"
				(scrollToEnd)="selectScrollToEnd()"
				(search)="selectSearch($event)"
			>
				<!-- <ng-template ng-label-tmp let-item="item">
					{{ item.name }}
				</ng-template> -->
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input *ngIf="multiple" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
                </ng-template>
			</ng-select>
		</div>
    `,
})

export class AppSelectFilterComponent implements OnInit {
    @Input() value: any; /// set and get value
    @Output() valueChange = new EventEmitter();
    @Input() items = [];
    @Input() label = '';
    @Input() placeholder = '';
    @Input() disabled = false;
    @Input() multiple = false;
    @Input() clearable = false;
    @Input() bindLabel = 'name';
    @Input() bindValue = 'id';
    @Input() isAppend = false;
    @Input() parentId = 'appFilter'; /***** Default filter box's ID where it's appended to *****/
    @Output() onChange = new EventEmitter();
    @Output() onClose = new EventEmitter();
    @Output() onScrollToEnd = new EventEmitter();
    @Output() onSearch = new EventEmitter();
    @Input() searchText = '';
    @Output() searchTextChange = new EventEmitter();
    @Input() searchable = true;
    @Input() hasIcon = false;
    searchChange: any;
    constructor() { }

    ngOnInit() {
        this.searchText = this.searchText ? this.searchText : '';
    }

    selectChange(e) {
        this.valueChange.emit(this.value);
        this.onChange.emit(e);
    }

    selectClose() {
        this.onClose.emit();
        this.searchText = '';
        this.searchTextChange.emit('');
        this.onSearch.emit(this.searchText);
    }

    selectScrollToEnd() {
        this.onScrollToEnd.emit();
    }

    selectSearch(term) {
        const searchText = term ? term.trimLeft() : '';
        if (!this.searchChange) {
            Observable.create((observer) => {
                this.searchChange = observer;
            })
                .pipe(debounceTime(500), distinctUntilChanged())
                .subscribe((text) => {
                    this.searchText = text;
                    this.searchTextChange.emit(this.searchText);
                    this.onSearch.emit(this.searchText);
                });
        }
        this.searchChange.next(searchText);
    }
}