import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Profile } from '../shared/reducers/model/profile.model';
import { AppState } from '../shared/reducers/app.state';
import { arrayToPipeString } from '../shared/helper/custom-function';

@Injectable({
	providedIn: 'root'
})
export class CurrentUserService {
	profile: Profile;

	constructor(private store: Store<AppState>) {
		/*
		 * Read data from ngrx/store
		 */
		this.store.select('profile').subscribe((res: any) => {
			this.profile = res[0];
		});
	}

	/**
	 * Check if current user is able to access somewhere via providing and their moduleCodes.
	 */
	isAccessible(moduleCodes: string[]) {
		const modules = this.profile.moduleCodes ? this.profile.moduleCodes : [];
		if (!modules.length || !moduleCodes.length) return false;
		else return modules.some(r => moduleCodes.indexOf(r) >= 0);
	}

	/**
	 * Check if current user has a module via an offerred string.
	 */
	hasModule(mainModuleName: string) {
		const modules = this.profile.moduleCodes ? this.profile.moduleCodes : [];
		if (!modules.length || !mainModuleName) return false;
		else
			return (
				modules.some(r => r === mainModuleName) ||
				modules.some(r => r === mainModuleName.split(':')[0]) ||
				modules.some(r => r.startsWith(`${mainModuleName}:`))
			);
	}

	isDistributor() {
		return this.profile.roleCode === 'distributor' ? true : false;
	}

	isAdmin() {
		return this.profile.roleCode === 'admin' ? true : false;
	}

	isSAPDistributor() {
		return this.profile.userCode === 'SAPDistributor';
	}

	get brandIds() {
		return this.profile.brandIds;
	}

	get moduleCodes() {
		return this.profile.moduleCodes;
	}
}
