import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './app-confirm.component.html',
	styleUrls: ['./app-confirm.component.scss']
})
export class AppConfirmComponent implements OnInit {
    @Input() id = 'app-confirm-modal';
	@Input() questionText = '';
	@Output() actionConfirm = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	activeInactive() {
		this.actionConfirm.next();
	}
}
