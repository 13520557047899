import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-status-filter',
	template: `
		<app-select-filter 
			[hasIcon] = "hasIcon"
			bindLabel = "name"
			bindValue = "id"
			placeholder = "Select Status"
			label = "Status"
			[clearable]="false"
			[multiple] = "false"
			[items] = "options"
			[(value)] = value
			(valueChange)="valueChange.emit(value)"
		>
		</app-select-filter>
    `,
})

export class AppStatusFilterComponent implements OnInit {
	@Input() multiple = false;
	@Input() value: any = null;
	@Output() valueChange = new EventEmitter();
	@Input() hasIcon = false;
	options = [
		{ id: '', name: 'All' },
		{ id: 'active', name: 'Active' },
		{ id: 'inactive', name: 'Inactive' },
	]
	constructor() { }

	ngOnInit() { }
}