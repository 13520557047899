import { Profile } from './model/profile.model';
import * as ProfileAction from './profile.actions';

// Section 2
export function reducer(state: Profile[] = [], action: ProfileAction.Actions) {
	// Section 3
	switch (action.type) {
		case ProfileAction.ADD_PROFILE:
			return [...state, action.payload];
		case ProfileAction.REMOVE_PROFILE:
			state.splice(action.payload, 1);
			return state;
		default:
			return state;
	}
}
