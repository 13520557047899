import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-attention-modal',
    template: `
        <div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="app-attention-modal-title"
        aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered new-modal-iorder" role="document ">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-header">
                            <h5 class="modal-title" id="idlePromptLabel">{{title}}</h5>
                        </div>
                        <div class="modal-body text-description">
                            <ng-content select="[app-attention-body]"></ng-content>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-order mb-0 mr-2 save mx-auto" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: []
})
export class AppAttentionModalComponent implements OnInit {
    @Input() id = 'app-attention-modal';
    @Input() title = 'Attention';

    constructor() { }

    ngOnInit() { }
}
