import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessPermissionService } from 'src/app/services/access-permission.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {
	constructor(private router: Router, public appPermService: AccessPermissionService) { }

	ngOnInit() {
		const modules = this.appPermService.accessModules || [];
		const accessibleModules = modules.filter(m => m.visible);
		const currentUrl = this.router.url;

		// if (accessibleModules && accessibleModules.length) {
		// 	const url = currentUrl == '/' ? accessibleModules[0].url : currentUrl;
		// 	this.router.navigate([url]);
		// }

		/*=================== Check accessible route b4 navigation ===================*/
		if (accessibleModules && accessibleModules.length) {
			if (currentUrl == '/') this.router.navigate([accessibleModules[0].url]);
			else {
				let [mainURL, query] = currentUrl.split('?');
				const queryObj = query ? JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : {};
				this.router.navigate([mainURL], queryObj);
			}
		}
	}
}
