import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Injectable({
	providedIn: 'root'
})
export class AlertMessageService {
	messageSuccess(): any {
		throw new Error('Method not implemented.');
	}
	constructor(
		private _alertService: Ng2IzitoastService,
		public iziToast: Ng2IzitoastService
	) {}
	alertSuccess(string: string) {
		return this._alertService.show({
			message: string,
			class: 'foo',
			progressBarColor: '#FFF',
			backgroundColor: '#053E97',
			titleColor: '#FFF',
			messageColor: '#FFF',
			close: false,
			iconColor: '#FFF'
		});
	}
	/**
	 * Alert Error
	 */
	alertError(string: string) {
		return this._alertService.show({
			message: string,
			class: 'foo',
			progressBarColor: '#D8000C',
			backgroundColor: '#FFD2D2',
			titleColor: '#D8000C',
			messageColor: '#D8000C',
			close: false
		});
	}
}
