import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncNumber'
})
export class TruncNumberPipe implements PipeTransform {
	transform(value: number, decimalPoint: number = 2): any {
		///// if value is valid number, do action.
		if ((value || value === 0) && !isNaN(value)) {
			const [number, decimal] = value.toString().split('.');

			// cut value after dot depends on given number, 'decimalPoint'.
			const decAfterSlice = (decimal || '').slice(0, decimalPoint);

			// add 0 to cut value after dot, if it's enough for requirement.
			const final = decAfterSlice
				? decAfterSlice.length === decimalPoint
					? decAfterSlice
					: decAfterSlice + '0'.repeat(decimalPoint - decAfterSlice.length)
				: '0'.repeat(decimalPoint - decAfterSlice.length);

			return `${number}.${final}`;
		}
		// return old value is it's not valid number.
		return value;
	}
}
