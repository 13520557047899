import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    template: `
        <label class="custom-checkbox m-0">
            <input
                type="checkbox"
                [id]="id"
                [ngModel]="value"
                (ngModelChange)="valueChange.emit($event)"
                (click)="onClick.emit($event)"
                (change)="onChange.emit($event)" />
            <span></span>
        </label>
    `
})
export class AppCheckboxComponent implements OnInit {
    @Input() id;
    @Input() value;
    @Output() valueChange = new EventEmitter();
    @Output() onChange = new EventEmitter();
    @Output() onClick = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
