export class FileDownload {
	today = new Date();
	date =
		this.today.getDate() < 10
			? `0${this.today.getDate()}`
			: `${this.today.getDate()}`;
	month =
		this.today.getMonth() + 1 < 10
			? `0${this.today.getMonth() + 1}`
			: `${this.today.getMonth() + 1}`;
	year = this.today.getFullYear();

	dateForName = `${this.date}-${this.month}-${this.year}`;

	constructor() {}

	downloadFileByPath(path) {
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = path;
		a.click();
		window.URL.revokeObjectURL(path);
		a.remove();
	}

	downloadFile(data, name) {
		const blob = new Blob([data]);
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download = `${name} ${this.dateForName}.xlsx`;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}

	downloadFilePDF(data, name) {
		const blob = new Blob([data]);
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download = `${name} ${this.dateForName}.pdf`;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}

	openFilePDF(data, newTab) {
		const blob = new Blob([data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		newTab.location.replace(url);
	}

	downloadAllFileTypes(link: string) {
		const url = link;
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.target = '_blank';
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	}
}
