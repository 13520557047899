import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from './services/login.service';

import * as ProfileAction from './shared/reducers/profile.actions';
import { AppState } from './shared/reducers/app.state';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { switchMap, take, tap, filter, map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private loginService: LoginService,
		private store: Store<AppState>
	) {}

	/**
	 * Check and Set Data to Ngrx Store
	 */
	getFromStoreOrAPI(): Observable<any> {
		return this.store.pipe(
			// get data from ngrx store
			select('profile'),
			tap((data: any) => {
				// check if ngrx store doesn't has data
				if (!data.length) {
					// request data from api
					this.loginService.getUserProfile().subscribe(
						(res: any) => {
							// set data to ngrx store
							this.store.dispatch(new ProfileAction.AddProfile(res));
						},
						() => {
							// error remove localStorage and redirect to login again
							localStorage.removeItem('token');
							localStorage.removeItem('isLoggedinExpire');
							localStorage.removeItem('roleCode');
							this.router.navigate(['/login']);
						}
					);
				}
			}),
			// check data in ngrx store again if has data
			filter((data: any) => data.length),
			take(1)
		);
	}
	canActivate() {
		return this.getFromStoreOrAPI().pipe(
			switchMap(() => of(true)),
			catchError(() => of(false))
		);
	}
}
