// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: 'https://iorder-api-dev.iqpstrading.com',
	clientId: '1234567890',
	authorization: 'Bearer 97f802abbf0509001481603fb75e970d3d43a39b',
    recaptcha: {
        siteKey: '6Lfk7E0iAAAAABQjGLGv2z_P_spOon1kLsvYcWCP',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
