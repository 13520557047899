import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-loading',
    styles: [
        `
            .loading-content {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                text-align: center;
                position: absolute;
                /* z-index: 100; */
                z-index: 1000 !important;
            }
            img {
                width: 70px;
                height: auto;
            }
        `
    ],
    template: `
        <!-- App Loading -->
        <div class="loading-content d-flex justify-content-center align-items-center" *ngIf="loading">
            <div class="loading-block d-flex justify-content-center align-items-center w-100 h-100">
				<div [class]="'lds-spinner ' + color" >
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
		    </div>
        </div>
    `
})
export class AppSectionLoadingComponent {
    @Input() loading = false;
    @Input() color: 'blue' | 'white' | 'yellow' = 'blue';
}
