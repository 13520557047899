import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class DropdownFilterService {
	header = this.globalService.getHeader();
	url: string = this.globalService.url + '/v1/admin';

	constructor(private globalService: GlobalService, private http: HttpClient) { }
	/**
	 * Get Trade Channel Dropdown
	 */
	getTradeChannel() {
		return this.http.get(`${this.url}/trade-channels?limit=100&status=active`, {
			headers: this.header
		});
	}

	/**
	 * Get RSM or ASM Dropdown
	 * @param code
	 * @param param
	 */
	getRSMOrASM(code, param, limit = 100, offset = 0) {
		return this.http.get(
			`${
			this.url
			}/sale-reps/managers/drop-down?roleCode=${code}&limit=${limit}&offset=${offset}&status=active${param}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get RSM or ASM Dropdown
	 * @param param
	 */
	getLevelSR(code, param, limit = 100, offset = 0) {
		return this.http.get(
			`${
			this.url
			}/sale-reps/drop-down-by-manager?roleCode=${code}&limit=${limit}&offset=${offset}&status=active${param}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get Sales Director Dropdown
	 */
	getSD(limit = 100, offset = 0) {
		return this.http.get(
			`${
			this.url
			}/sale-reps/managers/drop-down?limit=${limit}&offset=${offset}&roleCode=sd&status=active`,
			{
				headers: this.header
			}
		);
	}

	/***** New filter dropdown functions by params *****/
	getSDList(params: Object) {
		return this.http.get(`${this.globalService.url}/v1/admin/sale-reps/drop-down-by-manager-v2?roleCode=sd`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params)
			}
		);
	}

	getRSMList(params: Object) {
		return this.http.get(`${this.globalService.url}/v1/admin/sale-reps/drop-down-by-manager-v2?roleCode=rsm`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params)
			}
		);
	}

	getASMList(params: Object) {
		return this.http.get(`${this.globalService.url}/v1/admin/sale-reps/drop-down-by-manager-v2?roleCode=asm`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params)
			}
		);
	}

	getSRList(params: Object) {
		return this.http.get(`${this.globalService.url}/v1/admin/sale-reps/drop-down-by-manager-v2?roleCode=sr`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params)
			}
		);
	}

	/******************************************/

	/**
	 * Get District Dropdown
	 * @param param
	 * @param limit
	 * @param offset
	 */
	getDistricts(param, limit, offset) {
		if (!limit) limit = '100';
		if (!offset) offset = '0';
		return this.http.get(
			`${
			this.url
			}/districts?limit=${limit}&offset=${offset}&status=active${param}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get Cities Dropdown
	 * @param param
	 */
	getCities(params) {
		return this.http.get(`${this.url}/cities?limit=100&status=active`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	/**
	 * Get Regions Dropdown
	 */
	getRegions() {
		return this.http.get(`${this.url}/regions?limit=10&status=active`, {
			headers: this.header
		});
	}

	/**
	 * Get Sales  API
	 */
	getSales(param) {
		return this.http.get(
			`${this.url}/sale-reps/drop-down?limit=100&status=active${param}`,
			{
				headers: this.header
			}
		);
	}

	/**
	 * Get Red Customer Type
	 */
	getRedCustomerType() {
		return this.http.get(`${this.url}/outlets/red-customers`, {
			headers: this.header
		});
	}

	getDistributors(params: any) {
		return this.http.get(`${this.url}/distributors/drop-down`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}
}
