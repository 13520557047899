import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-mini-select-filter',
    template: `
		<div class="position-relative d-flex justify-content-center">
			<div class="headline-filter-0">{{label}}</div>
			<div class="mini-filter">
				<ng-select [items]="options" [(ngModel)]="value" (ngModelChange)="valueChange.emit(value)" bindLabel="name" bindValue="id"
				[clearable]="false">
					<ng-template ng-label-tmp let-item="item">
						{{ item?.name | titlecase }}
					</ng-template>
				</ng-select>
			</div>
		</div>
	`
})
export class AppMiniSelectFilterComponent {
    @Input() label = '';
    @Input() value: number;
    @Input() options = [];
    @Output() valueChange = new EventEmitter();
}
