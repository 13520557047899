import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { City } from 'src/app/shared/models/user';

@Component({
	selector: 'app-city',
	templateUrl: './app-city.component.html',
	styleUrls: ['./app-city.component.scss'],
})
export class AppCityComponent implements OnInit {
	cities: City[];
	selectedCity: number;
	@Input() cityId: number;
	@Output() change = new EventEmitter();

	constructor(private dropdownFilter: DropdownFilterService) {}

	ngOnInit() {
		this.getCities('');
	}

	/**
	 * Get Cities Dropdown with API
	 */
	getCities(id): void {
		let param = {};
		if (id) param['regionId'] = id;
		param['sortBy']='name asc';

		this.dropdownFilter.getCities(param).subscribe((res: any) => {
			this.cities = res.data;
			this.cities.unshift({
				id: null,
				name: 'All',
				regionId: null,
				code: undefined,
				sapCityCode: undefined,
			});
			this.cityId
				? (this.selectedCity = this.cityId)
				: (this.selectedCity = this.cities[0].id);
		});
	}

	/**
	 * Filter by City
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			cityId: event.id,
		});
	}
}
