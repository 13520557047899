import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appNumberFormat'
})
export class AppNumberFormatPipe implements PipeTransform {
    transform(value: any, decimalLength: number = 2): any {
        value = value || 0;
        let formated = value;
        /***** if value is valid number, do action. *****/
        if ((value || value === 0) && !isNaN(value)) {
            const [number, decimal] = value.toString().split('.');
            /***** Format Number section *****/
            const finalNumber = (number || '').replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            /***** if Number has decimal section, format it *****/
            let finalDecimal = '';
            if(decimal) {
                /*****  cut value after dot depends on given number, 'decimalLength'. *****/
                const decAfterSlice = (decimal || '').slice(0, decimalLength);

                /*****  add 0 to cut value after dot, if it's not enough for requirement. *****/
                finalDecimal = decAfterSlice
                    ? decAfterSlice.length === decimalLength
                        ? decAfterSlice
                        : decAfterSlice + '0'.repeat(decimalLength - decAfterSlice.length)
                    : '0'.repeat(decimalLength - decAfterSlice.length);
            }
            
            if (finalDecimal) formated =  `${finalNumber}.${finalDecimal}`;
            else formated =  finalNumber + '';
        }
        /***** replace minus sign with parenthese *****/
        formated += '';
        formated = formated.includes('-') ? `(${formated.split('-')[1]})` : formated;
        return formated;
    }
}