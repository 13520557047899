import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	url = environment.apiUrl;
	authorization = environment.authorization;
	clientId = environment.clientId;
	getHeader() {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: localStorage.getItem('token')
		});
	}

	constructor() { }

	isArray(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    }

	cleanParams(obj: Object, deletedFieldNames: string[] = [], allowedZero: boolean = false) {
        if (obj) {
            Object.keys(obj).forEach((o) => {
                if (
                    (((allowedZero && obj[o] !== 0 && !obj[o]) || (!allowedZero && !obj[o])) && obj[o] !== false) ||
                    (this.isArray(obj[o]) && !obj[o].length) ||
                    (deletedFieldNames && deletedFieldNames.length && deletedFieldNames.includes(o))
                )
                    delete obj[o];
            });
            return obj as HttpParams;
        }
        return null;
    }
}
