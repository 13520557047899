import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-loading-data',
	templateUrl: './app-loading-data.component.html',
	styleUrls: ['./app-loading-data.component.scss']
})
export class AppLoadingDataComponent implements OnInit {
	@Input() totalItems: number;
	@Input() loading: boolean;

	constructor() {}

	ngOnInit() {
		this.totalItems = this.totalItems ? this.totalItems : 0;
		this.loading = this.loading ? this.loading : false;
	}
}
