import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ProductsService } from 'src/app/services/index-service';
import { arrayToPipeString } from 'src/app/shared/helper/custom-function';

@Component({
	selector: 'app-product-filter',
	template: `
		<app-select-filter 
			[hasIcon] = "hasIcon"
			bindLabel = "name"
			bindValue = "id"
			placeholder = "Select Product"
			label = "Product"
			[clearable]="multiple"
			[multiple] = "multiple"
			[items] = "metadata.data"
			[(value)] = value
			(valueChange)="valueChange.emit(value)"
			(onScrollToEnd) = "getProducts()"
			(onSearch) = "search($event)"
		>
		</app-select-filter>
    `,
})

export class AppProductFilterComponent implements OnInit {
	@Input() multiple = false;
	@Input() params: Object = {};
	@Input() value: number = null;
	@Output() valueChange = new EventEmitter();
	@Input() brandIds = null;
	@Input() hasIcon = false;
	metadata = {
		data: [],
		limit: 100,
		total: 0,
		search: '',
	};
	userBrandIds = this.currentUser ? this.currentUser.brandIds : [];
	constructor(private productService: ProductsService, private currentUser: CurrentUserService) { }

	ngOnInit() {
		this.getProducts();
	}

	getProducts() {
		let mtd = this.metadata;
		if (!mtd.data.length || mtd.data.length < mtd.total) {
			const params = {
				status: 'active',
				search: mtd.search,
				limit: mtd.limit,
				offset: mtd.data.length,
				brandIds: arrayToPipeString(this.brandIds) || arrayToPipeString(this.userBrandIds),
				...this.params,
			}
			this.productService
				.getProducts(params)
				.subscribe((res: any) => {
					const mappedData = (res.data || []).map(d => {
						return {
							id: d.id,
							name: d.displayName,
						}
					})
					mtd.data = [...mtd.data, ...mappedData];
					mtd.total = res.metadata.total;

					if (!this.multiple) {
						/***** Check if "All" option is already added *****/
						const index = mtd.data.findIndex(p => p.id == '' && p.name == 'All');
						if (index < 0) {
							mtd.data.unshift({
								id: '',
								name: 'All',
							});
							this.value = this.value || mtd.data[0].id;
						}
					}
				});
		}

	}

	search(text) {
		this.metadata.search = text;
		this.metadata.data = [];
		this.getProducts();
	}

	refresh() {
		setTimeout(() => {
			this.value = null;
			this.metadata.data = [];
			this.getProducts();
		}, 500);
	}
}