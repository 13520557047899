import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CompressImageService } from 'src/app/services/compress-image.service';
import { UploadServiceService } from 'src/app/services/upload-service.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
	selector: 'app-crop-image-modal',
	templateUrl: './app-crop-image.component.html',
	styleUrls: ['./app-crop-image.component.scss']
})
export class AppCropImageComponent implements OnInit {
	imageUrl;
	croppedImage: Blob;

	@Input() imageChangedEvent: any;
	@Input() width = 512;
	@Input() height = 512;
	@Input() imageDetail = { name: '', type: '' };
	@Output() upload = new EventEmitter();
	constructor(
		private _compressImage: CompressImageService,
		private _uploadService: UploadServiceService
	) {}

	ngOnInit() {}

	/**
	 * Get image file(blob) from crop modal.
	 */
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.file;
	}

	/**
	 * Compress and upload image after cropped.
	 */
	async onUploadCrop() {
		// check if file not image
		if (
			this.imageDetail.type &&
			this.imageDetail.type.split('/')[0] === 'image'
		) {
			// convert file blob to file image
			const file = new File([this.croppedImage], this.imageDetail.name, {
				type: this.imageDetail.type
			});

			// Compress image function.
			const image: any = await this._compressImage.compress(
				file,
				this.imageDetail.type,
				this.width,
				this.height
			);

			// upload file to server
			this._uploadService.uploadOneFile(image).subscribe((res: any) => {
				this.upload.next({
					imageName: res.name,
					imageUrl: res.logo,
					fileId: res.id
				});
			});
		}
	}
}
