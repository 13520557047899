import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { AuthGuard } from './auth.service';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';

export const router: Routes = [
	{ 	path: '', 
		canActivate: [AuthGuard],
		component: MainComponent, 
	},
	{
		path: 'portal-configuration',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/portal-config/portal-config.module#PortalConfigModule'
			}
		]
	},
	{
		path: 'promotions',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/promotions/promotions.module#PromotionsModule'
			}
		]
	},
	{
		path: 'report',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/report/report.module#ReportModule'
			}
		]
	},
	{
		path: 'distributor',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/customers/distributor/distributor.module#DistributorModule'
			}
		]
	},
	{
		path: 'customer-location',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/customers/customer-location/customer-location.module#CustomerLocationModule'
			}
		]
	},
	{
		path: 'customer-group',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/customers/customer-group/customer-group.module#CustomerGroupModule'
			}
		]
	},
	{
		path: 'customer',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/customers/customer/customer.module#CustomerModule'
			}
		]
	},
	{
		path: 'products',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/products/products.module#ProductsModule'
			}
		]
	},
	{
		path: 'orders',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/orders/orders.module#OrdersModule'
			}
		]
	},
	{
		path: 'primary-orders',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/primary-order/primary-order.module#PrimaryOrderModule'
			}
		]
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/sales-volume/sales-volume.module#SalesVolumeModule'
			}
		]
	},
	{
		path: 'brand-dashboard',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/brand-dashboard/brand-dashboard.module#BrandDashboardModule'
			}
		]
	},
	{
		path: 'users',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/users/users.module#UsersModule'
			}
		]
	},
	{
		path: 'inventory',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/inventory/inventory.module#InventoryModule'
			}
		]
	},
	{
		path: 'sale-route',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/sale-route/salte-route.module#SalteRouteModule'
			}
		]
	},
	{
		path: 'login',
		component: LoginLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/login/login.module#LoginModule'
			}
		]
	},
	{
		path: 'not-found',
		component: LoginLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/not-found/not-found.module#NotFoundModule'
			}
		]
	},
	{
		path: 'app-setting',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/app-setting/app-setting.module#AppSettingModule'
			}
		]
	},
	{
		path: 'reset/password',
		component: LoginLayoutComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/reset-password/reset-password.module#ResetPasswordModule'
			}
		]
	},
	{
		path: 'portal-user',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/system-user/system-user.module#SystemUserModule'
			}
		]
	},
	{
		path: 'route',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/route/route.module#RouteModule'
			}
		]
	},
	{
		path: 'kpi-target',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/kpi/kpi.module#KPIModule'
			}
		]
	},
	{
		path: 'kpi-target-product',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/kpi-by-product/kpi-by-product.module#KPIByProductModule'
			}
		]
	},
	{
		path: 'survey',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren: './modules/survey/survey.module#SurveyModule'
			}
		]
	},
	{
		path: 'profit-story',
		canActivate: [AuthGuard],
		component: MainComponent,
		children: [
			{
				path: '',
				loadChildren:
					'./modules/profit-elearning/profit-elearning.module#ProfitElearningModule'
			}
		]
	},

	{ path: '**', redirectTo: 'not-found' }
];

export const routes: ModuleWithProviders = RouterModule.forRoot(router, {
	preloadingStrategy: PreloadAllModules
});
