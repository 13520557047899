import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { Region } from 'src/app/shared/models/user';

@Component({
	selector: 'app-region',
	templateUrl: './app-region.component.html',
	styleUrls: ['./app-region.component.scss']
})
export class AppRegionComponent implements OnInit {
	@Input() regionId: number;
	regions: Region[];
	selectedRegion: number;
	@Output() change = new EventEmitter();

	constructor(private dropdownFilter: DropdownFilterService) {}

	ngOnInit() {
		this.getRegions();
	}

	/**
	 * Get Regions Dropdown with API
	 */
	getRegions(): void {
		this.dropdownFilter.getRegions().subscribe((res: any) => {
			this.regions = res.data;
			this.regions.unshift({
				id: null,
				name: 'All',
				code: null
			});

			this.regionId
				? (this.selectedRegion = this.regionId)
				: (this.selectedRegion = this.regions[0].id);
		});
	}

	/**
	 * Filter by Region
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			regionId: event.id
		});
	}
}
