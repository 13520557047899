import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'countTime'
})
export class CountTime implements PipeTransform {
	transform(value: string): any {
		if (value) {
			let hour = '00',
				min = '00',
				second = '00';
			if (value.includes(':')) {
				[hour, min, second] = value.split(':');
			}
			return `${hour}hr ${min}mn ${second}s`;
		}
		return value;
	}
}
