import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SAPPullService } from 'src/app/services/sap-pull.service';
import { AlertMessageService } from 'src/app/shared/global-component/alert-message.service';

@Component({
	selector: 'app-pull-button',
	template: `
		<div class="position-relative">
			<button class="btn btn-order px-3" (click)="click($event)" [disabled]="loading">
				{{text}}
			</button>
			<app-block-loading *ngIf="loading" [loading]="loading" class="position-absolute w-100 h-100" style="top: 0; left: 0"></app-block-loading>
		</div>
	`,
})
export class AppPullButtonComponent implements OnInit {
	@Input() text = 'Pull';
	@Input() type = '';
	@Output() onClick = new EventEmitter();
	@Output() onSuccess = new EventEmitter();
	@Output() onFail = new EventEmitter();

	loading = false;

	constructor(private pullService: SAPPullService, private alertMsg: AlertMessageService) { }

	ngOnInit() { }

	click(evt) {
		this.onClick.emit(evt);
		this.pull();
	}

	pull() {
		if (!this.type) return;
		this.loading = true;
		setTimeout(() => {
			this.pullFunction();
		}, 2500);
	}

	pullFunction() {
		switch (this.type) {
			/**** Other *****/
			case PullType.WAREHOUSE:
				this.pullWarehouse();
				break;
			case PullType.SAP_STOCK:
				this.pullSAPInventory();
				break;
			case PullType.CUST_PRODUCT_PRICE:
				this.pullCustomerProductPrice();
				break;
			case PullType.TRADE_CHANNEL:
				this.pullTradeChannel();
				break;
			case PullType.PRICE_LIST:
				this.pullPriceList();
				break;
			case PullType.OUTLET_TYPE:
				this.pullOutletType();
				break;
			case PullType.CREDIT_TERM:
				this.pullCreditTerm();
				break;
			case PullType.PRIMARY_DELIVERY_CLOSE_OPEN:
				this.pullPrimaryDeliveryCloseOpen();
				break;
			case PullType.TAX:
				this.pullTax();
				break;
			case PullType.SAP_MANAGEMENT:
				this.pullSAPManagement();
				break;
			/***** Customer Group *****/
			case PullType.CUSTOMER_GROUP:
				this.pullCustomerGroup();
				break;
			/***** Address *****/
			case PullType.COMMUNE:
			case PullType.DISTRICT:
			case PullType.CITY:
			case PullType.REGION:
				this.pullAddressTeam();
				break;
			/***** Promotion *****/
			case PullType.PROMOTION:
			case PullType.PROMOTION_PRODUCT_GROUP:
			case PullType.PROMOTION_OUTLET_GROUP:
				this.pullPromotionTeam();
				break;
			case PullType.PRODUCT_LIST:
				this.pullProductTeam();
				break;
			case PullType.PRODUCT_SIZE:
				this.pullPackSize();
				break;
			case PullType.PRODUCT_FLAVOR:
				this.pullFlavor();
				break;
			case PullType.PRODUCT_BRAND:
				this.pullBrand();
				break;
			case PullType.PRODUCT_GROUP:
				this.pullProductGroup();
				break;
			default:
				return;
		}
	}

	/********************
	** ADDRESS (pull parent first)
	********************/
	pullCommune() {
		this.loading = true;
		return this.pullService.pullCommune()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}
	pullDistrict() {
		this.loading = true;
		return this.pullService.pullDistrict()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.DISTRICT) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullCommune();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.DISTRICT) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullCommune();
				}
			);
	}
	pullCity() {
		this.loading = true;
		return this.pullService.pullCity()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.CITY) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullDistrict();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.CITY) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullDistrict();
				}
			);
	}
	pullRegion() {
		this.loading = true;
		return this.pullService.pullRegion()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.REGION) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullCity();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.REGION) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullCity();
				}
			);
	}
	pullAddressTeam() {
		this.pullRegion();
	}

	/********************
	** PROMOTION (pull parent first)
	********************/
	pullPromotion() {
		this.loading = true;
		return this.pullService.pullPromotion()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}
	pullPromotionProductGroup() {
		this.loading = true;
		return this.pullService.pullPromotionProductGroup()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PROMOTION_PRODUCT_GROUP) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullPromotion();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PROMOTION_PRODUCT_GROUP) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullPromotion();
				}
			);
	}
	pullPromotionOutletGroup() {
		this.loading = true;
		return this.pullService.pullPromotionOutletGroup()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PROMOTION_OUTLET_GROUP) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullPromotionProductGroup();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PROMOTION_OUTLET_GROUP) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullPromotionProductGroup();
				}
			);
	}
	pullPromotionTeam() {
		this.pullPromotionOutletGroup();
	}

	/********************
	** PROMOTION (pull parent first)
	********************/
	pullProductGroup() {
		this.loading = true;
		return this.pullService.pullProductGroup()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}
	pullProductList() {
		this.loading = true;
		return this.pullService.pullProductList()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_LIST) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
                        /***** Pull customer product price along with product list *****/
                        this.pullCustomerProductPrice();
						/***** Pull as parent  *****/
					} else this.pullProductGroup();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_LIST) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
                        /***** Pull customer product price along with product list *****/
                        this.pullCustomerProductPrice();
						/***** Pull as parent  *****/
					} else this.pullProductGroup();
				}
			);
	}
	pullPackSize() {
		this.loading = true;
		return this.pullService.pullPackSize()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_SIZE) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullProductList();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_SIZE) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullProductList();
				}
			);
	}
	pullFlavor() {
		this.loading = true;
		return this.pullService.pullFlavor()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_FLAVOR) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullPackSize();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_FLAVOR) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullPackSize();
				}
			);
	}
	pullBrand() {
		this.loading = true;
		return this.pullService.pullBrand()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_BRAND) {
						this.alertMsg.alertSuccess('Data pulled successfully.');
						this.onSuccess.emit(res);
						/***** Pull as parent  *****/
					} else this.pullFlavor();
				},
				(err: any) => {
					/***** Pull as it ownself *****/
					if (this.type === PullType.PRODUCT_BRAND) {
						this.alertMsg.alertError(err.error.message);
						this.onFail.emit(err);
						/***** Pull as parent  *****/
					} else this.pullFlavor();
				}
			);
	}
	pullProductTeam() {
		this.pullBrand();
	}

	/********************
	** OTHER 
	********************/
	pullWarehouse() {
		return this.pullService.pullWarehouse()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullSAPInventory() {
		return this.pullService.pullSAPInventory()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullCustomerProductPrice() {
		return this.pullService.pullCustomerProductPrice()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
                    if (this.type === PullType.CUST_PRODUCT_PRICE) {
                        this.alertMsg.alertSuccess('Data pulled successfully.');
                        this.onSuccess.emit(res);
                    }
				},
				(err: any) => {
                    if (this.type === PullType.CUST_PRODUCT_PRICE) {
                        this.alertMsg.alertError(err.error.message);
                        this.onFail.emit(err);
                    }
				}
			);
	}

	pullTradeChannel() {
		return this.pullService.pullTradeChannel()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullPriceList() {
		return this.pullService.pullPriceList()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullOutletType() {
		return this.pullService.pullOutletType()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullCreditTerm() {
		return this.pullService.pullCreditTerm()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullPrimaryDeliveryCloseOpen() {
		return this.pullService.pullPrimaryDeliveryCloseOpen()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullTax() {
		return this.pullService.pullTax()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullSAPManagement() {
		return this.pullService.pullSAPManagement()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}

	pullCustomerGroup() {
		return this.pullService.pullCustomerGroup()
			.pipe((res) => {
				this.loading = false;
				return res;
			}).subscribe(
				(res: any) => {
					this.alertMsg.alertSuccess('Data pulled successfully.');
					this.onSuccess.emit(res);
				},
				(err: any) => {
					this.alertMsg.alertError(err.error.message);
					this.onFail.emit(err);
				}
			);
	}
}

enum PullType {
	/**** Other *****/
	WAREHOUSE = 'WAREHOUSE',
	SAP_STOCK = 'SAP_STOCK',
	CUST_PRODUCT_PRICE = 'CUST_PRODUCT_PRICE',
	TRADE_CHANNEL = 'TRADE_CHANNEL',
	PRICE_LIST = 'PRICE_LIST',
	OUTLET_TYPE = 'OUTLET_TYPE',
	CREDIT_TERM = 'CREDIT_TERM',
	PRIMARY_DELIVERY_CLOSE_OPEN = 'PRIMARY_DELIVERY_CLOSE_OPEN',
	TAX = 'TAX',
	SAP_MANAGEMENT = 'SAP_MANAGEMENT',
	CUSTOMER_GROUP = 'CUSTOMER_GROUP',
	/**** Address *****/
	REGION = 'REGION',
	CITY = 'CITY',
	DISTRICT = 'DISTRICT',
	COMMUNE = 'COMMUNE',
	/**** Promotion *****/
	PROMOTION = 'PROMOTION',
	PROMOTION_PRODUCT_GROUP = 'PROMOTION_PRODUCT_GROUP',
	PROMOTION_OUTLET_GROUP = 'PROMOTION_OUTLET_GROUP',
	/**** Product *****/
	PRODUCT_LIST = 'PRODUCT_LIST',
	PRODUCT_FLAVOR = 'PRODUCT_FLAVOR',
	PRODUCT_BRAND = 'PRODUCT_BRAND',
	PRODUCT_SIZE = 'PRODUCT_SIZE',
	PRODUCT_GROUP = 'PRODUCT_GROUP',


}
