import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class BrandDashboardService {
	url: string = this.globalService.url;
	header = this.globalService.getHeader();
	constructor(private globalService: GlobalService, private http: HttpClient) { }

	getPromotions(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/promotion-list`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	exportPromotions(params?: any) {
		return this.http.get(`${this.url}/v1/admin/reports/exports/pmi-promotion`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	getInventories(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/inventory-summary`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	getInventoriesV2(type: string, params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/inventory-summary/${type}`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	exportInventoriesV2(type: string, params?: any) {
		return this.http.get(`${this.url}/v1/admin/reports/exports/pmi-inventory?reportType=${type}`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	getEnrollments(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/enrollments`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	getCustomerSummary(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/customer-summary`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}	

	getSalesSummary(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/sales-summary`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	getSalesSummaryList(params?: any) {
		return this.http.get(`${this.url}/v1/admin/dashboards/list-sales-summary`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}

	exportSalesSummary(params?: any) {
		return this.http.get(`${this.url}/v1/admin/reports/exports/pmi-sale-summary`, {
			headers: this.header,
			params: this.globalService.cleanParams(params)
		});
	}
}
