import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppLoadingDataComponent } from './directives/app-loading-data/app-loading-data.component';
import { AppSearchboxComponent } from './directives/app-searchbox/app-searchbox.component';
import { AppPaginationComponent } from './directives/app-pagination/app-pagination.component';
import { AppImportExcelComponent } from './directives/app-import-excel/app-import-excel.component';
import { AppConfirmComponent } from './directives/app-confirm/app-confirm.component';
import { AppSalesDropdownComponent } from './directives/dropdown-filter/app-sales-rep/app-sales-dropdown.component';
import { AppDistributorComponent } from './directives/dropdown-filter/app-distributor/app-distributor.component';
import { AppRegionComponent } from './directives/dropdown-filter/app-region/app-region.component';
import { AppCityComponent } from './directives/dropdown-filter/app-city/app-city.component';
import { AppStatusComponent } from './directives/dropdown-filter/app-status/app-status.component';
import { AppTradeChannelComponent } from './directives/dropdown-filter/app-trade-channel/app-trade-channel.component';
import { AppSalesDirectorComponent } from './directives/dropdown-filter/app-sales-director/app-sales-director.component';
import { AppBrandComponent } from './directives/dropdown-filter/app-brand/app-brand.component';
import { AppFlavorComponent } from './directives/dropdown-filter/app-flavor/app-flavor.component';
import { AppPackSizeComponent } from './directives/dropdown-filter/app-pack-size/app-pack-size.component';
import { AppRsmComponent } from './directives/dropdown-filter/app-rsm/app-rsm.component';
import { AppAsmComponent } from './directives/dropdown-filter/app-asm/app-asm.component';
import { AppCropImageComponent } from './directives/app-crop-image/app-crop-image.component';
import { AppDistrictComponent } from './directives/dropdown-filter/app-district/app-district.component';
import { AppStartEndDateComponent } from './directives/dropdown-filter/app-start-end-date/app-start-end-date.component';
import { TruncNumberPipe } from './pipe/trunc-number.pipe';
import { AppRedCustomersComponent } from './directives/dropdown-filter/app-red-customers/app-red-customers.component';
import { CountTime } from './pipe/count-time.pipe';
import { OrderStatus } from './pipe/order-status.pipe';
import { StringNoDashPipe } from './pipe/string-no-dash.pipe';
import { AppSelectFilterComponent } from './directives/filters/app-select-filter.component';
import { AppDistributorFilterComponent } from './directives/filters/app-distributor-filter.component';
import { AppSDFilterComponent } from './directives/filters/app-sd-filter.component';
import { AppRSMFilterComponent } from './directives/filters/app-rsm-filter.component';
import { AppASMFilterComponent } from './directives/filters/app-asm-filter.component';
import { AppSRFilterComponent } from './directives/filters/app-sr-filter.component';
import { AppRegionFilterComponent } from './directives/filters/app-region-filter.component';
import { AppProvinceFilterComponent } from './directives/filters/app-city-filter.component';
import { AppBrandFilterComponent } from './directives/filters/app-brand-filter.component';
import { AppProductFilterComponent } from './directives/filters/app-product-filter.component';
import { AppDateFilterComponent } from './directives/filters/app-date-filter.component';
import { AppDateFormatPipe } from './pipe/date-format.pipe';
import { AppPullButtonComponent } from './directives/app-pull-button/app-pull-button.component';
import { AppBlockLoadingComponent } from './directives/app-block-loading/app-block-loading.component';
import { AppWarehouseComponent } from './directives/dropdown-filter/app-warehouse/app-warehouse.component';
import { AppSendEmailModalComponent } from './directives/app-send-email-modal/app-send-email-modal.component';
import { AppPickProductTargetModalComponent } from './feature-component/app-pick-product-target-modal/app-pick-product-target-modal.component';
import { AppSectionLoadingComponent } from './directives/app-section-loading/app-section-loading.component';
import { AppNumberFormatPipe } from './pipe/number-format.pipe';
import { AppStatusFilterComponent } from './directives/filters/app-status-filter.component';
import { AppOrderSourceFilterComponent } from './directives/filters/app-order-source-filter.component';
import { AppIdlePromptComponent } from './directives/app-attention-modal/app-idle-prompt.component';

import { AppTableFilterComponent } from './directives/dropdown-filter/app-table-filter/app-table-filter.component';
import { AppRegionalFilterComponent } from './directives/filters/app-regional-filter.component';
import { AppRegionalTableFilterComponent } from './directives/filters/app-regional-table-filter.component';
import { AppAttentionModalComponent } from './directives/app-attention-modal/app-attention-modal.component';
import { AppMiniSelectFilterComponent } from './directives/dropdown-filter/app-mini-select-filter/app-mini-select-filter.component';
import { AppCheckboxComponent } from './directives/app-checkbox/app-checkbox.component';







const components = [
	AppPullButtonComponent,
	AppBlockLoadingComponent,
	SidebarComponent,
	NavigationComponent,
	FooterComponent,
	AppLoadingDataComponent,
	AppSearchboxComponent,
	AppPaginationComponent,
	AppImportExcelComponent,
	AppConfirmComponent,
	AppSalesDropdownComponent,
	AppDistributorComponent,
	AppRegionComponent,
	AppBrandComponent,
	AppFlavorComponent,
	AppPackSizeComponent,
	AppWarehouseComponent,
	AppCityComponent,
	AppStatusComponent,
	AppTradeChannelComponent,
	AppSalesDirectorComponent,
	AppRsmComponent,
	AppAsmComponent,
	AppCropImageComponent,
	AppDistrictComponent,
	AppStartEndDateComponent,
	TruncNumberPipe,
	AppDateFormatPipe,
	AppNumberFormatPipe,
	CountTime,
	OrderStatus,
	StringNoDashPipe,
	AppRedCustomersComponent,
	AppSelectFilterComponent,
	AppDistributorFilterComponent,
	AppSDFilterComponent,
	AppStatusFilterComponent,
    AppOrderSourceFilterComponent,
	AppRSMFilterComponent,
	AppASMFilterComponent,
	AppSRFilterComponent,
	AppRegionFilterComponent,
	AppRegionalFilterComponent,
	AppRegionalTableFilterComponent,
	AppProvinceFilterComponent,
	AppBrandFilterComponent,
	AppProductFilterComponent,
	AppDateFilterComponent,
	AppSendEmailModalComponent,
	AppPickProductTargetModalComponent,
	AppSectionLoadingComponent,
    AppIdlePromptComponent,

	AppTableFilterComponent,
    AppAttentionModalComponent,
    AppMiniSelectFilterComponent,
    AppCheckboxComponent
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		NgSelectModule,
		BsDatepickerModule.forRoot(),
		DatepickerModule.forRoot(),
		ImageCropperModule,
	],
	declarations: [

		components
	],
	exports: [
		ReactiveFormsModule,
		FormsModule,
		NgSelectModule,
		BsDatepickerModule,
		DatepickerModule,
        ImageCropperModule,
		components,
	],
	providers: [DatePipe]
})
export class SharedModule {}
