import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';

@Component({
	selector: 'app-pack-size',
	template: `
		<div class="position-relative d-flex justify-content-center">
			<div class="headline-filter-0">{{text}}</div>
			<div class="mini-filter">
				<ng-select [items]="items?.data" [(ngModel)]="selectedId" (ngModelChange)="selectedIdChange.emit(selectedId)" bindLabel="name" bindValue="id"
				[clearable]="false">
					<ng-template ng-label-tmp let-item="item">
						{{ item?.name | titlecase }}
					</ng-template>
				</ng-select>
			</div>
		</div>
	`
})
export class AppPackSizeComponent implements OnInit {
	@Input() text = 'Pack Size';
	@Input() selectedId: number;
	@Output() selectedIdChange = new EventEmitter();
	items = {
		data: [],
		limit: 100,
		total: 0,
	}

	constructor(private _productService: ProductsService,) { }

	ngOnInit() {
		this.getData();
	}

	getData() {
		if (this.items.data.length && this.items.data.length >= this.items.total) return;
		this._productService.packSizeFilter(this.items.limit, this.items.data.length).subscribe((res: any) => {
			const mappedData = res.data.map(d => {
				return {
					id: d.id,
					name: d.packSize
				}
			})
			this.items.data = [...this.items.data, ...mappedData];
			this.items.total = res.metadata.total;
			if (this.items.data.length) {
				if (this.items.data.length < this.items.total) this.getData();
				else this.items.data.unshift({
					brandCode: '',
					id: 0,
					logo: '',
					name: 'All',
					status: ''
				});
			}
		});
	}
}
