import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class OutletTargetService {
	header = this.globalService.getHeader();
	url = this.globalService.url;

	constructor(private globalService: GlobalService, private http: HttpClient) { }

	getTargets(params) {
		return this.http.get(
			`${this.url}/v1/admin/outlet-targets`,
			{
				headers: this.header,
				params: this.globalService.cleanParams(params),
			}
		);
	}

	createTarget(body) {
		return this.http.post(`${this.url}/v1/admin/outlet-targets`, body, {
			headers: this.header
		});
	}

	getTargetDetail(id) {
		return this.http.get(`${this.url}/v1/admin/outlet-targets/${id}`, {
			headers: this.header
		});
	}

	updateTarget(id, body) {
		return this.http.put(`${this.url}/v1/admin/outlet-targets/${id}`, body, {
			headers: this.header
		});
	}

	toggleTargetStatus(id, body: Object) {
		return this.http.put(`${this.url}/v1/admin/outlet-targets/${id}/update-status`, body, {
			headers: this.header
		});
	}

	importTarget(obj) {
		return this.http.post(
			`${this.url}/v1/admin/master-data/imports/outlet-targets`,
			obj,
			{
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			}
		);
	}

	exportTemplate() {
		return this.http.get(
			`${this.url}/v1/admin/master-data/exports/outlet-targets?limit=0`,
			{
				headers: this.header,
				responseType: 'blob',
			}
		);
	}

	exportErrorLogs() {
		return this.http.get(
			`${this.url}/v1/admin/master-data/exports/outlet-targets/errors`,
			{
				headers: this.header,
				responseType: 'blob',
			}
		);
	}

	exportAll(params) {
		return this.http.get(`${this.url}/v1/admin/master-data/exports/outlet-targets`, {
			headers: this.header,
			params: params,
			responseType: 'blob',
		});
	}
}
