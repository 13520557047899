import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-idle-prompt',
    template: `
        <div class="modal fade" id="app-idle-prompt" tabindex="-1" role="dialog" aria-labelledby="app-idle-prompt-title"
        aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-dialog-centered new-modal-iorder" role="document ">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-header">
                            <h5 class="modal-title" id="idlePromptLabel">Attention</h5>
                        </div>
                        <div class="modal-body text-description">
                            <div class="mb-2"><b>Session Expired</b></div>
                            <div>You have been inactive more than 30 minutes.<br>Please login again.</div>
                            <!--===== Don't remove this!!!. It's count down spin =====-->
                            <!-- <div class="idle-cound-down mt-4">
                                <div class="spinner"></div>
                                <span class="number">{{remainingSecond}}</span>
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-order mb-0 mr-2 save mx-auto" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: []
})
export class AppIdlePromptComponent implements OnInit {
    @Input() inactionSecond = 0;
    @Input() remainingSecond = 0;

    constructor() { }

    ngOnInit() { }
}
