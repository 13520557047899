import { FormGroup } from '@angular/forms';
import { Chart } from 'chart.js';
import * as moment from 'moment';

export function chartPercentage(takenNumber: number, totalNumber) {
    return +Math.round(totalNumber ? ((takenNumber || 0) * 100) / totalNumber : 0);
}

export function textCenteroughtDB() {
    // var div = document.getElementById('app-content');

    // function css(element, property) {
    //     return window.getComputedStyle(element, null).getPropertyValue(property);
    // }

    // const fontStyle = css(div, 'font-family');
    // console.log(fontStyle,'fontStyle');

    Chart.pluginService.register({
        beforeDraw: function (chart) {
            if (chart.config.options.elements['center']) {
                var width = chart['chart'].width,
                    height = chart['chart'].height,
                    ctx = chart['chart'].ctx;
                ctx.restore();
                var fontSize = (height / 114).toFixed(2);
                ctx.font = fontSize + 'em Arial';
                ctx.textBaseline = 'middle';

                const centerConfig = chart.config.options.elements['center'];
                var text = centerConfig.text,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
                const color = centerConfig.color || '#000';
                ctx.fillStyle = color;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }
    });
}

export function arrayToPipeString(arr: any[]) {
    if (arr && arr.length) {
        return arr.toString().split(',').join('|');
    }
    return '';
}

export function pressNumber(event: KeyboardEvent, useDot: boolean = false) {
    event = event ? event : (window.event as KeyboardEvent);
    const charCode = event.which ? event.which : event.keyCode;
    const value = (event.target as any).value || null;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        /***** Discuss using dot sign *****/
        if (!(useDot && charCode == 46)) {
            return false;
        } else {
            if (
                charCode === 46 &&
                (value.length === 0 || value.indexOf('.') !== -1) ///Prevent multiple dots or dot at first digit
            ) {
                event.preventDefault();
                return;
            }
        }
    }
}

export function getDuration(
    startISODate,
    endISODate,
    withDate = true,
    withTime = true,
    primaryOnly = false
) {
    const start = moment(startISODate);
    const end = moment(endISODate);

    if (start.isValid() && end.isValid()) {
        /***** Year *****/
        const years = end.diff(start, "years");
        start.add(years, "years");

        /***** Month *****/
        const months = end.diff(start, "months");
        start.add(months, "months");

        /***** Day *****/
        const days = end.diff(start, "days");
        start.add(days, "days");

        /***** Hour *****/
        const hours = end.diff(start, "hours");
        start.add(hours, "hours");

        /***** Minute *****/
        const minutes = end.diff(start, "minutes");
        start.add(minutes, "minutes");

        /***** Second *****/
        const seconds = end.diff(start, "seconds");

        /***** Concate year, month and day *****/
        let duration = "";
        let primary = "";
        if (withDate) {
            const y = years ? years + ` year${years > 1 ? "s" : ""}` : "";
            duration += y;
            duration += duration && months ? " " : "";

            const mo = months ? months + ` month${months > 1 ? "s" : ""}` : "";
            duration += mo;
            duration += duration && days ? " " : "";

            const d = days ? days + ` day${days > 1 ? "s" : ""}` : "";
            duration += d;

            /***** Primary only *****/
            primary = primary || y || mo || d;
        }

        if (withTime) {
            duration += duration && minutes ? " " : "";

            const h = hours ? hours + ` hour${hours > 1 ? "s" : ""}` : "";
            duration += h;
            duration += duration && hours ? " " : "";

            const m = minutes ? minutes + ` minute${minutes > 1 ? "s" : ""}` : "";
            duration += m;
            duration += duration && minutes ? " " : "";

            const s = seconds ? seconds + ` second${seconds > 1 ? "s" : ""}` : "";
            duration += s;

            /***** Primary only *****/
            primary = primary || h || m || s;
        }

        return primaryOnly ? primary : duration;
    }

    return "";
};

/* ========== Trim Form Data Vale ========== */
export function trimReactFormCtrl(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
        if (form.controls[key] && typeof form.controls[key].value === 'string') {
            form.controls[key].setValue(form.controls[key].value.trim());
        }
    });
    return form;
}
