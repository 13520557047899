import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropdownFilterService } from 'src/app/services/dropdown-filter.service';
import { FilterASM } from 'src/app/shared/models/user';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
	selector: 'app-asm',
	templateUrl: './app-asm.component.html',
	styleUrls: ['./app-asm.component.scss']
})
export class AppAsmComponent implements OnInit {
	asm: FilterASM[];
	@Input() selectedASM = null;
	disabled = false;
	@Output() change = new EventEmitter();

	constructor(
		private dropdownFilter: DropdownFilterService,
		private cUser: CurrentUserService
	) {}

	ngOnInit() {
		if (this.cUser.profile) {
			if (this.cUser.profile.roleCode === 'rsm') {
				this.getASM(this.cUser.profile.id);
			} else if (this.cUser.profile.roleCode === 'asm') {
				this.disabled = true;
			} else {
				this.getASM('');
			}
		}
	}

	/**
	 * Get asm Dropdown with API
	 */
	getASM(id): void {
		let param = '';
		if (id) param = `&managerId=${id}`;
		this.dropdownFilter.getRSMOrASM('asm', param).subscribe((res: any) => {
			this.asm = res.data;
			this.asm.unshift({
				id: null,
				fullName: 'All',
				userCode: ''
			});
			this.selectedASM = this.selectedASM ? this.selectedASM : this.asm[0].id;
		});
	}

	/**
	 * Filter by asm
	 * @param event
	 */
	onChange(event) {
		this.change.next({
			asmId: event.id
		});
	}
}
