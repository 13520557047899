import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import * as $ from 'jquery';

import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './layouts/main/main.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { AuthGuard } from './auth.service';
import { SharedModule } from './shared/shared.module';
import { GlobalService } from './services/global.service';
import { LoginService } from './services/login.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorService } from './services/global-error-handler.service';
import { StoreModule } from '@ngrx/store';
import { reducer } from './shared/reducers/profile.reducer';
import { UsersService } from './services/users.service';
import { UploadServiceService } from './services/upload-service.service';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ToImageUrlPipe } from './shared/pipe/to-image-url.pipe';
import { CustomersService } from './services/customers.service';
import { DistributorComponent } from './modules/customers/distributor/distributor.component';
import { FileDownload } from './shared/global-component/file-download';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { GlobalHttpInterceptorService } from './services/global-http-interceptor.service';

@NgModule({
	declarations: [
		AppComponent,
		MainComponent,
		LoginLayoutComponent,
		ToImageUrlPipe,
		DistributorComponent,

	],
	imports: [
		BrowserModule,
		routes,
		SharedModule,
		HttpClientModule,
		StoreModule.forRoot({ profile: reducer }),
		Ng2IziToastModule,
		SelectDropDownModule,
        NgIdleKeepaliveModule.forRoot(),
	],
	providers: [
		AuthGuard,
		GlobalService,
		LoginService,
		// {
		// 	provide: ErrorHandler,
		// 	useClass: GlobalErrorService
		// },
        { 
            provide: HTTP_INTERCEPTORS, 
            useClass: GlobalHttpInterceptorService, 
            multi: true
        ​},
		UsersService,
		UploadServiceService,
		CustomersService,
		FileDownload
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
