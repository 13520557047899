import { Component, OnInit } from '@angular/core';
import {
	FormGroup,
	Validators,
	FormBuilder
} from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

/*
/get date from observable
*/
import { Store } from '@ngrx/store';
import { Profile } from '../../shared/reducers/model/profile.model';
import { AppState } from '../reducers/app.state';
import { Md5 } from 'ts-md5';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AlertMessageService } from '../global-component/alert-message.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
	changePasswordForm: FormGroup;
	submitted = false;
	missMatch = false;
	profile: Profile;
	auth: string;
	msgError: string;

	constructor(
		private formBuilder: FormBuilder,
		private currentUserService: CurrentUserService,
		private router: Router,
		private loginService: LoginService,
		private alert: AlertMessageService,
		private store: Store<AppState>
	) {
		this.profile = this.currentUserService.profile;
	}

	ngOnInit() {
		this.changePasswordForm = this.formBuilder.group({
			oldPwd: ['', Validators.required],
			newPwd: ['', Validators.required],
			confirmPwd: ['', Validators.required]
		});
	}

	getAuth() {}

	/*
	 * Show Sidabar
	 */
	toggleSidebar() {
		$('#sidebar').toggleClass('active');
		$('#navigation').toggleClass('navbar-collapes');
		$('#content').removeClass('content-collapes');
	}

	/*
	 * Call Change Password Modal
	 */
	changePasswordModal() {
		this.submitted = false;
		this.changePasswordForm.reset();
		(<any>$('#changePasswordModal')).modal('show');
	}

	/*
	 * Validation confirm password
	 */
	checkMatch() {
		if (
			this.changePasswordForm.get('newPwd').value ===
			this.changePasswordForm.get('confirmPwd').value
		) {
			this.missMatch = false;
		} else {
			this.missMatch = true;
		}
	}

	/*
	 * Control validtion form change password
	 */
	get controlForm() {
		return this.changePasswordForm.controls;
	}

	/*
	 * Submit change new password
	 */
	changePwd() {
		this.submitted = true;
		if (this.changePasswordForm.invalid || this.missMatch) {
			return;
		}
		const oldpassword: any = Md5.hashStr(
			this.changePasswordForm.get('oldPwd').value
		);
		const newpassword: any = Md5.hashStr(
			this.changePasswordForm.get('newPwd').value
		);
		const body = {
			oldPassword: oldpassword.toUpperCase(),
			newPassword: newpassword.toUpperCase()
		};

		this.changePassword(body);
	}

	/*
	 * Change Password
	 */
	changePassword(body: object) {
		this.loginService.changePassword(body).subscribe((res: any) => {
			(<any>$('#changePasswordModal')).modal('hide');
			this.alert.alertSuccess('Password successfully changed.');
			setTimeout(() => {
				this.signOut();
			}, 1000);
		});
	}

	/*
	 * Sign Out
	 */
	signOut() {
		this.loginService.logout();
	}
}
