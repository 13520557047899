import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare let $:any;

@Component({
	selector: 'app-send-email-modal',
	template: `
		<div class="modal fade" [id]="id" tabindex="-1" role="dialog" aria-labelledby="send-to-email-title" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered new-modal-iorder" role="document ">
				<div class="modal-content">
					<div class="modal-body">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">Send to Email</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body text-description">
							<span class="error">Separate your email by comma.</span>
							<textarea class="form-control mb-3" rows="3" placeholder="example1@email.com,example2@email.com" [(ngModel)]="emails"></textarea>
							<div class="error" *ngIf="submitted && isRequired && !emails">Email is required.</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-order mb-0 mr-2 save" (click)="submit()">Yes</button>
							<button class="btn btn-order mb-0 mr-2" data-dismiss="modal" aria-label="Close" (click)="onCancel.emit()">No</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class AppSendEmailModalComponent implements OnInit {
	@Input() isRequired = false;
	@Input() id = 'app-send-email-modal';
	@Output() onOk = new EventEmitter();
	@Output() onCancel = new EventEmitter();
	emails = '';
	submitted = false;

	constructor() { }

	ngOnInit() {
		this.showModal();
	}

	submit() {
		this.submitted = true;
		if (this.isRequired && !this.emails) return;
		$(`#${this.id}`).modal('hide');
        this.onOk.emit(this.emails);
        setTimeout(() => {
            this.emails = null;
        }, 1000);
	}

	showModal() {
        $(document)
            .off('show.bs.modal', `#${this.id}`)
            .on('show.bs.modal', `#${this.id}`, () => {
                this.emails = '';
                this.submitted = false;
            });
    }

	show() {
		$(`#${this.id}`).modal('show');
	}


}
